import { lighten, darken } from "polished";

const primary = "hsla(222, 15%, 20%, 1)";

const palette = {
  empty: "", // Fixes a bug with styled components that appends a coma
  "primary-dark": darken(0.12, primary),
  "primary-light": lighten(0.47, primary),
  // This variable is for capacitor use only, it represents the primary color.
  "primary-hex": "#2b303b",
  "secondary-s40-l45": "hsla(60, 40%, 45%, 1)",
  "secondary-s100-l35": "hsla(61, 100%, 35%, 1)",
  "blue-s40-l25": "hsla(195, 40%, 25%, 1)",
  "main-s15-l20": "hsla(222, 15%, 20%, 1)",
  "main-s15-l30": "hsla(222, 15%, 30%, 1)",
  "grey-blue-s15-l80": "hsla(227, 15%, 80%, 1)",
  "purple-s85-l45": primary,
  "purple-s72-l92": "hsla(257, 72%, 92%, 1)",
  "gray-s15-l90": "hsla(190, 15%, 90%, 1)",
  primary: "var(--palette-purple-s85-l45)",
  "green-s100-l40": "hsla(88, 100%, 40%, 1)",
  "green-s63-l42": "hsla(145, 63%, 42%, 1)",
  "green-s55-l15-a10": "hsla(140, 55%, 15%, 0.1);",
  "green-s41-l43": "hsla(61, 41%, 43%, 1)",
  "red-s61-l87": "hsla(0, 61%, 87%, 1)",
  "red-s60-l60": "hsla(2, 60%, 60%, 1)",
  "red-s80-l65": "hsla(0, 80%, 65%, 1)",
  "blue-s89-l52": "hsla(214, 89%, 52%, 1)",
  "yellow-s100-l55": "hsla(50, 100%, 54%, 1)",
  "white-s0-l0-a40": "hsla(0, 0%, 0%, 0.4)",
  // This variable is for capacitor use only, it represents the black color.
  "black-hex": "#000000",
  black: "hsla(0, 0%, 0%, 1)",
  "black-s0-l10": "hsla(0, 0%, 10%, 1)",
  "black-s0-l15": "hsla(0, 0%, 15%, 1)",
  "gray-s0-l20-a04": "hsla(0, 0%, 20%, 0.04)",
  "gray-s0-l20-a08": "hsla(0, 0%, 20%, 0.08)",
  "gray-s0-l20-a16": "hsla(0, 0%, 20%, 0.16)",
  "gray-s0-l20-a20": "hsla(0, 0%, 20%, 0.20)",
  "gray-s0-l20-a70": "hsla(0, 0%, 20%, 0.70)",
  "gray-s0-l20": "hsla(0, 0%, 20%, 1)",
  "gray-s0-l30": "hsla(0, 0%, 30%, 1)",
  "gray-s0-l35": "hsla(0, 0%, 35%, 1)",
  "gray-s0-l40-a40": "hsla(0, 0%, 40%, 0.4)",
  "gray-s0-l40": "hsla(0, 0%, 40%, 1)",
  "gray-s0-l70-a48": "hsla(0, 0%, 70%, 0.48)",
  "gray-s0-l50": "hsla(0, 0%, 50%, 1)",
  "gray-s0-l70": "hsla(0, 0%, 70%, 1)",
  "gray-s0-l75": "hsla(0, 0%, 75%, 1)",
  "gray-s0-l80": "hsla(0, 0%, 80%, 1)",
  "gray-s0-l85": "hsla(0, 0%, 85%, 1)",
  "gray-s0-l90": "hsla(0, 0%, 90%, 1)",
  "gray-s0-l92": "hsla(0, 0%, 92%, 1)",
  "gray-s0-l95": "hsla(0, 0%, 95%, 1)",
  "gray-s0-l97": "hsla(0, 0%, 97%, 1)",
  // This variable is for capacitor use only, it represents the white color.
  "white-hex": "#ffffff",
  white: "hsla(0, 0%, 100%, 1)",
  text: {
    primary: "var(--palette-primary)",
    white: "var(--palette-white)"
  }
};

const fonts = {
  primary: "'Montserrat', sans-serif"
};

export const columns = 12;

export const columnsMaxTotalWidth = "120rem";

export const gridMinMargin = "0.8rem";

export const gridTotalMinMargin = `calc(${gridMinMargin} * 2)`;

export const gridMargin = `minmax(${gridMinMargin}, 1fr)`;

export const columnsTotalWidth = `calc(100% - ${gridTotalMinMargin})`;

export const columnMaxWidth = `calc(${columnsMaxTotalWidth} / ${columns})`;

export const columnWidth = `minmax(0, min(calc(${columnsTotalWidth} / ${columns}), ${columnMaxWidth}))`;

const sizes = {
  page: {
    minWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    maxWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    minHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    maxHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    columns: `[fullBleed-start] ${gridMargin} [content-start] repeat(${columns}, ${columnWidth}) [content-end] ${gridMargin} [fullBleed-end]`
  },
  navbar: {
    height: "calc(8rem + env(safe-area-inset-top))",
    "height-mobile": "calc(10.8rem + env(safe-area-inset-top))",
    "height-mobile-without-search": "calc(5.8rem + env(safe-area-inset-top))"
  }
};

const variables = { palette, fonts, sizes };

export default variables;
