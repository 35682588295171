import React from "react";

import Styles from "./Radio.styles";
import { RadioProps as Props } from "./Radio.types";

const Radio: React.FC<Props> = props => {
  const { label, value, onClick = value => {} } = props;
  const { name, disabled = false, className, isSelected } = props;

  return (
    <Styles className={`Radio ${className}`}>
      <input
        className="Radio__input"
        type="radio"
        name={name}
        value={value}
        onChange={() => onClick(value)}
        id={value.toString()}
        disabled={disabled}
        checked={isSelected}
      />
      <label htmlFor={value.toString()} className="Radio__label">
        {label}
      </label>
    </Styles>
  );
};

Radio.defaultProps = {
  className: ""
};

export default Radio;
