import { initServices } from "@anefi/services";

import { queryClient } from "./reactQuery.config";
import { CONSTANTS } from "config/constants";

const { PLATFORM } = CONSTANTS;

initServices({
  platform: PLATFORM,
  shouldMock: false,
  queryClient,
  mockWithInitialUserData: false
});
