// useDefaultCurrencyFormatter customHook
import { useCountries } from "@anefi/contexts";
import { useMemo } from "react";

const useDefaultCurrencyFormatter = () => {
  const { selectedCountry } = useCountries();
  const { currency: defaultCurrency } = selectedCountry;
  const { currencySymbol: defaultSymbol } = selectedCountry;

  // If the local property is in "en-Us" then the dollar sign appears on the left
  return useMemo(
    () => ({
      currency: defaultCurrency,
      symbol: defaultSymbol,
      locale: "en-US"
    }),
    [defaultCurrency, defaultSymbol]
  );
};

export default useDefaultCurrencyFormatter;
