// DocumentInput function helpers and data
import { AnefiDocumentType } from "@anefi/types";
import { CountryCode } from "@artisan-commerce/types";

const countryTypeDocumentInvalid =
  "DocumentInput: The type of document entered is invalid for the selected country.";
const countryCodeInvalid =
  "DocumentInput: The country code entered is not currently supported.";

/** Identifier coefficients (cédula) */
const idCoefficients = [2, 1, 2, 1, 2, 1, 2, 1, 2];
/** Legal person RUC coefficients (RUC de persona jurídica) */
const legalPersonRUCCoefficients = [4, 3, 2, 7, 6, 5, 4, 3, 2];
/** Public company RUC coefficients (RUC de empresa pública) */
const publicCompanyRUCCoefficients = [3, 2, 7, 6, 5, 4, 3, 2];

/**
 * Validates a CI (Cédula de identidad - Ecuador).
 *
 * @param {string} document The CI to be validated
 * @returns {string|undefined} A message if the CI is invalid, undefined if the CI is valid
 */
export const validateCI = (document: string): string | undefined => {
  if (
    validateProvince(document.substring(0, 2)) &&
    isIDNaturalPerson(document) &&
    validateID(document, idCoefficients)
  ) {
    return;
  }

  return "Cédula de identidad inválida";
};

/**
 * Validates a RUC (Registro único tributario - Ecuador).
 *
 * @param {string} document The RUC to be validated
 * @returns {string|undefined} A message if the RUC is invalid, undefined if the RUC is valid
 */
export const validateRUC = (document: string): string | undefined => {
  const validateNaturalRUC =
    isRUCNaturalPerson(document) && validateID(document, idCoefficients);

  const validateLegalRUC =
    isRUCLegalPerson(document) &&
    validateRUCByCoefficients(
      document,
      legalPersonRUCCoefficients,
      document.charAt(9)
    );

  const validateCompanyRUC =
    isRUCLegalCompany(document) &&
    validateRUCByCoefficients(
      document,
      publicCompanyRUCCoefficients,
      document.charAt(8)
    );

  if (validateNaturalRUC || validateLegalRUC || validateCompanyRUC) return;

  return "RUC inválido";
};

/**
 * Validates a CI (Cédula de ciudadanía - Colombia).
 *
 * @param {string} document The CI to be validated
 * @returns {string|undefined} A message if the CI is invalid, undefined if the CI is valid
 */
export const validateCI_CO = (document: string): string | undefined => {
  if (/^\d{6,11}$/.test(document)) return;
  return "Cédula de ciudadanía inválida";
};

/**
 * Validates a CE (Cédula de extranjería - Colombia).
 *
 * @param {string} document The CE to be validated
 * @returns {string|undefined} A message if the CE is invalid, undefined if the CE is valid
 */
export const validateCE = (document: string): string | undefined => {
  if (/^\d{5,7}$/.test(document)) return;
  return "Cedula de extranjería inválida";
};

/**
 * Validates a NIT (Número de identificación tributaria - Colombia).
 *
 * @param {string} document The NIT to be validated
 * @returns {string|undefined} A message if the NIT is invalid, undefined if the NIT is valid
 */
export const validateNIT = (document: string): string | undefined => {
  if (/^\d{7,10}$/.test(document)) return;
  return "NIT inválido";
};

/**
 * Validates a passport based on his length.
 *
 * @param {string} document The passport to be validated
 * @returns {string|undefined} A message if the passport is invalid, undefined if the passport is valid
 */
export const validatePassport = (document: string): string | undefined => {
  if (document.length > 20 || document.length < 4) {
    return "Pasaporte inválido";
  }
};

/**
 * Validates a RUT (Registro Único Tributario - Colombia) based on his length.
 *
 * @param {string} document The document to be validated
 * @returns {string|undefined} A message if the RUT is invalid, undefined if the RUT is valid
 */
export const validateRut = (document: string): string | undefined => {
  if (document.length <= 20) return "RUT inválido";
};

/**
 * Validates an Id based on his length.
 *
 * @param {string} identification The document to be validated
 * @returns {boolean} True if the validation is correct, false if not
 */
const isIDNaturalPerson = (identification: string) => {
  return identification.length === 10;
};

/**
 * Validates a RUC from a natural person.
 *
 * @param {string} identification The document to be validated
 * @returns {boolean} True if the validation is correct, false if not
 */
const isRUCNaturalPerson = (identification: string) => {
  return (
    identification.length === 13 &&
    identification.charAt(2) !== "6" &&
    identification.charAt(2) !== "9" &&
    identification.substring(10, 13) === "001"
  );
};

/**
 * Validates the latest digits from a RUC.
 *
 * @param {string} identification The document to be validated
 * @returns {boolean} True if the validation is correct, false if not
 */
const lastRUCDigits = (identification: string) => {
  return (
    identification.length === 13 && identification.substring(10, 13) === "001"
  );
};

/**
 * Validates the RUC from a legal person.
 *
 * @param {string} identification The document to be validated
 * @returns {boolean} True if the validation is correct, false if not
 */
const isRUCLegalPerson = (identification: string) => {
  return lastRUCDigits(identification) && identification.charAt(2) === "9";
};

/**
 * Validates the RUC from a legal company.
 *
 * @param {string} identification The document to be validated
 * @returns {boolean} True if the validation is correct, false if not
 */
const isRUCLegalCompany = (identification: string) => {
  return lastRUCDigits(identification) && identification.charAt(2) === "6";
};

/**
 * Validates an Id from Ecuador based on the document and coefficients.
 *
 * @param {string} identification The document to be validated
 * @param {number[]} coefficients Coefficients used to validate the document
 * @returns {boolean} True if the validation is correct, false if not
 */
const validateID = (identification: string, coefficients: number[]) => {
  const id = identification;
  let addDigitsByCoefficient = 0;
  let value = 0;

  for (const [i, coefficient] of Array.from(coefficients?.entries())) {
    const digit = Number(id.charAt(i)) * 1;
    value = coefficient * digit;
    if (value > 9) {
      value = value - 9;
    }
    addDigitsByCoefficient = addDigitsByCoefficient + value;
  }

  let myModule = addDigitsByCoefficient % 10;
  myModule = myModule === 0 ? 10 : myModule;
  const result = 10 - myModule;
  const lastDigit = Number(id.charAt(9)) * 1;

  return result === lastDigit;
};

/**
 * Validates a RUC based on the document, coefficients and the check digit.
 *
 * @param {string} identification The document to be validated
 * @param {number[]} coefficients Coefficients used to validate the document
 * @param {string} checkDigit Check digit which will be use to validate
 * @returns {boolean} True if the validation is correct, false if not
 */
const validateRUCByCoefficients = (
  identification: string,
  coefficients: number[],
  checkDigit: string
) => {
  const id = identification;
  const verifier = Number(checkDigit) * 1;
  let addTotalsDigitsByCoefficient = 0;
  let digit = 0;
  let value = 0;

  for (const [i, coefficient] of Array.from(coefficients?.entries())) {
    digit = Number(id.charAt(i)) * 1;
    value = coefficient * digit;
    addTotalsDigitsByCoefficient = addTotalsDigitsByCoefficient + value;
  }

  const myModule = addTotalsDigitsByCoefficient % 11;
  let result = 0;

  if (myModule !== 0) {
    result = 11 - myModule;
  }

  return result === verifier;
};

/**
 * Validates if the first two characters of a document against the number of provinces.
 *
 * @param {string} identification The first two characters of a document validated
 * @returns {boolean} True if the first two characters are within range, false if not
 */
const validateProvince = (identification: string) => {
  return parseInt(identification, 10) >= 0 || parseInt(identification, 10) < 24;
};

/**
 * Function that validates Ecuadorian documents.
 *
 * @param {string} documentType The selected document type
 * @param {string} value The document to be validated
 * @returns {string|undefined} A message if the validation fails, undefined if not
 */
const validateECDocuments = (
  documentType: AnefiDocumentType,
  value: string
): string | undefined => {
  switch (documentType) {
    case "3":
      return validatePassport(value);
    case "1":
      return validateCI(value);
    case "2":
      return validateRUC(value);
    default:
      console.error(countryTypeDocumentInvalid);
      return countryTypeDocumentInvalid;
  }
};

/**
 * Function that validates the supported documents from the supported countries.
 *
 * @param {CountryCode} countryCode The selected country code
 * @param {AnefiDocumentType} documentType The selected document type
 * @param {string} value The document to be validated
 * @returns {string|undefined} A message if the validation fails, undefined if not
 */
export const validateDocument = (
  countryCode: CountryCode,
  documentType: AnefiDocumentType,
  value: string
): string | undefined => {
  switch (countryCode) {
    case "EC":
      return validateECDocuments(documentType, value);
    default:
      console.error(countryCodeInvalid);
      return countryCodeInvalid;
  }
};
