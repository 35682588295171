import React from "react";

import Styles from "./NoCoverageNotification.styles";
import { NoCoverageNotificationProps as Props } from "./NoCoverageNotification.types";
import Button from "components/global/Button/Button";
import { CONSTANTS } from "config/constants";
import { defaultFunction } from "utils/common.utils";

import LocationSVG from "../../../../../public/assets/images/location.svg";

const { WITH_PURCHASE } = CONSTANTS.FEATURE_FLAGS;

const NoCoverageNotification: React.FC<Props> = props => {
  const { className, onClose = defaultFunction, opened = true } = props;
  const { onButton } = props;

  if (!WITH_PURCHASE) return null;

  return (
    <Styles
      className={`NoCoverageNotification ${className}`}
      opened={opened}
      onClose={onClose}
    >
      <div className="NoCoverageNotification__icon">
        <LocationSVG viewBox="0 0 16 22" />
      </div>
      <p className="NoCoverageNotification__title">Estás fuera de cobertura</p>
      <p className="NoCoverageNotification__description">
        Intenta cambiar tu ubicación para continuar
      </p>
      <Button
        type="BORDER"
        className="NoCoverageNotification__button NoCoverageNotification__button--desktop"
        onClick={onButton}
      >
        Cambiar de ubicación
      </Button>
      <Button
        type="BORDER"
        className="NoCoverageNotification__button NoCoverageNotification__button--mobile"
        onClick={onButton}
      >
        Cambiar
      </Button>
    </Styles>
  );
};

NoCoverageNotification.defaultProps = {
  className: ""
};

export default NoCoverageNotification;
