// Auth context
import { updateUser } from "@anefi/services";
import React, { createContext, FC, useCallback, useMemo } from "react";
import { useEffect, useState } from "react";

import { AuthProviderProps as Props } from "./auth.context.types";
import { UserMigrateSuccessOTP, UserMigrateInfo } from "./auth.context.types";
import { UserSignInInfo } from "./auth.context.types";
import { AuthProviderValue } from "./auth.context.types";
import { auth, Services } from "config/artisn.config";

// @ts-ignore
export const AuthContext = createContext<AuthProviderValue>({});

const AuthProvider: FC<Props> = props => {
  const [isAnonymous, setIsAnonymous] = useState<boolean>();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [signInUser, setSignInUser] = useState<Partial<UserSignInInfo>>();
  const [signInUserMigrate, setSignInUserMigrate] =
    useState<Partial<UserMigrateInfo>>();
  const [signInUserMigrateSuccessOTP, setSignInUserMigrateSuccessOTP] =
    useState<Partial<UserMigrateSuccessOTP>>();

  useEffect(() => {
    const authSubscriber = auth().onAuthStateChanged(async user => {
      updateUser(user);
      const { uid, isAnonymous } = user ?? {};

      if (uid) {
        setIsAnonymous(!!isAnonymous);
        setIsSignedIn(!isAnonymous);
      } else {
        setIsAnonymous(true);
        setIsSignedIn(false);
        signInAnonymously();
      }
    });

    return authSubscriber;
  }, []);

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    const response = await auth().signInWithEmailAndPassword(email, password);
    return response;
  };

  const sendPasswordResetEmail = async (email: string) => {
    return await auth().sendPasswordResetEmail(email);
  };

  const signInAnonymously = async () => {
    return await auth().signInAnonymously();
  };

  const signInWithGoogle = useCallback(async () => {
    const provider = new Services.GoogleAuthProvider();
    const response = await auth().signInWithPopup(provider);
    const { credential } = response;
    if (!credential || auth().currentUser?.isAnonymous) return response;
    await auth().signInWithCredential(credential);
    return response;
  }, []);

  const signInWithFacebook = useCallback(async () => {
    const provider = new Services.FacebookAuthProvider();
    const response = await auth().signInWithPopup(provider);
    const { credential } = response;
    if (!credential || auth().currentUser?.isAnonymous) return response;
    await auth().signInWithCredential(credential);
    return response;
  }, []);

  // TODO: Implemente signInWithApple on web
  // const signInWithApple = useCallback(async () => {
  //   const appleAuthRequestResponse = await appleAuth.performRequest({
  //     requestedOperation: appleAuth.Operation.LOGIN,
  //     requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME]
  //   });

  //   if (!appleAuthRequestResponse.identityToken) {
  //     throw new Error("Apple Sign-In failed - no identify token returned");
  //   }

  //   const { identityToken, nonce, fullName } = appleAuthRequestResponse;
  //   const { givenName, familyName } = fullName ?? {};

  //   AsyncStorage.setItem(
  //     SSO_USER_TOKEN,
  //     JSON.stringify({ name: givenName, lastName: familyName, phoneNumber: "" })
  //   );
  //   const appleCredential = Services.AppleAuthProvider.credential(
  //     identityToken,
  //     nonce
  //   );

  //   const response = await auth().signInWithCredential(appleCredential);
  //   return response;
  // }, []);

  const registerWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    return await auth().createUserWithEmailAndPassword(email, password);
  };

  const getUID = () => {
    return auth()?.currentUser?.uid;
  };

  const getEmail = () => {
    return auth()?.currentUser?.email;
  };

  const getSignInMethodsForEmail = useCallback(async (email: string) => {
    return await auth().fetchSignInMethodsForEmail(email);
  }, []);

  const linkWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      const credentialEP = Services.EmailAuthProvider.credential(
        email,
        password
      );

      return auth().currentUser?.linkWithCredential(credentialEP);
    },
    []
  );

  const resetAuthContext = useCallback(() => {
    setIsAnonymous(true);
    setIsSignedIn(false);
  }, []);

  const value: AuthProviderValue = useMemo(() => {
    return {
      isAnonymous,
      isSignedIn,
      signInWithEmailAndPassword,
      signInWithGoogle,
      signInWithFacebook,
      signInAnonymously,
      sendPasswordResetEmail,
      registerWithEmailAndPassword,
      uid: getUID(),
      email: getEmail(),
      resetAuthContext,
      signInUser,
      setSignInUser,
      signInUserMigrate,
      setSignInUserMigrate,
      signInUserMigrateSuccessOTP,
      setSignInUserMigrateSuccessOTP,
      getSignInMethodsForEmail,
      linkWithEmailAndPassword
    };
  }, [
    isAnonymous,
    isSignedIn,
    signInWithGoogle,
    signInWithFacebook,
    resetAuthContext,
    signInUser,
    signInUserMigrate,
    signInUserMigrateSuccessOTP,
    getSignInMethodsForEmail,
    linkWithEmailAndPassword
  ]);

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export default AuthProvider;
