import { useRouter } from "next/router";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";

import { TalkShopProviderProps as Props } from "./talkShop.context.types";
import { TalkShopProviderValue } from "./talkShop.context.types";
import { TalkShopProvider as ITalkShopProvider } from "services/talkShop/talkShop/talkShop.service.types";
import { sanitizeQueryParams } from "utils/common.utils";

// @ts-ignore
export const TalkShopContext = createContext<TalkShopProviderValue>({});

const TalkShopProvider: React.FC<Props> = props => {
  const { query } = useRouter();
  const { provider, identifier } = sanitizeQueryParams(query);
  const [talkShopProvider, setTalkShopProvider] = useState<ITalkShopProvider>();
  const [talkShopIdentifier, setTalkShopIdentifier] = useState("");

  useEffect(() => {
    if (!provider || !identifier) return;
    setTalkShopProvider(provider as ITalkShopProvider);
    setTalkShopIdentifier(identifier);
  }, [provider, identifier]);

  const value: TalkShopProviderValue = useMemo(() => {
    return {
      talkShopProvider,
      talkShopIdentifier
    };
  }, [talkShopProvider, talkShopIdentifier]);

  return (
    <TalkShopContext.Provider value={value}>
      <ContextDevTool
        context={TalkShopContext}
        id="talkShop"
        displayName="TalkShop"
      />
      {props.children}
    </TalkShopContext.Provider>
  );
};

export default TalkShopProvider;
