import { usePayments } from "@anefi/contexts";
import { PaymentProviderId } from "@anefi/contexts";
import { useFetchCards } from "@anefi/services";
import { Storage } from "@capacitor/storage";
import { useEffect } from "react";

import { CONSTANTS } from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";

const { CARD_TOKEN } = CONSTANTS.STORAGE;

const useListenPayments = () => {
  const { selectedCard, setSelectedCard } = usePayments();
  const { selectedPaymentProviderId } = usePayments();
  const auth = useAuth();
  const { data: cards } = useFetchCards(auth);

  // Get card from local storage and set it on context
  useEffect(() => {
    (async () => {
      /* TODO: Esto es temporal hasta que tengamos integrado la billetera de
      tarjetas en payphone */
      if (selectedPaymentProviderId === PaymentProviderId.PAY_PHONE_ID) {
        setSelectedCard(undefined);
        return;
      }
      const { value } = await Storage.get({ key: CARD_TOKEN });
      const lastSelectedCard = value ? JSON.parse(value) : undefined;
      if (!cards) {
        setSelectedCard(lastSelectedCard);
        return;
      }
      const [firstCard] = cards;
      const card = lastSelectedCard ?? firstCard;
      setSelectedCard(card);
    })();
  }, [cards, setSelectedCard, selectedPaymentProviderId]);

  // Every time the selected card changes, save it on local storage
  useEffect(() => {
    if (!selectedCard) return;
    Storage.set({
      key: CARD_TOKEN,
      value: JSON.stringify(selectedCard)
    });
  }, [selectedCard]);
};

export default useListenPayments;
