import { Modal } from "artisn-ui-react";
import styled from "styled-components";

import { UserVerifyModalStyledProps as Props } from "./UserVerifyModal.types";

const UserVerifyModalStyled = styled(Modal)<Props>`
  box-shadow: 0rem 0.8rem 1.2rem var(--palette-green-s55-l15-a10);

  & > .Modal__body {
    background-color: var(--palette-white);
    min-width: 28rem;
  }

  .UserVerifyModal {
    &__close {
      display: flex;
      justify-content: flex-end;
      padding: 0;
    }

    &__title {
      font-weight: 500;
      font-size: 2.8rem;
      line-height: 3.6rem;
      padding-bottom: 0.8rem;
    }

    &__subtitle {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
      padding-bottom: 2.4rem;
    }

    &__form {
      &__document {
        border: 0;
        padding-bottom: 1.2rem;
      }

      &__documentTitle {
        padding-bottom: 0.8rem;
        font-size: 1.2rem;
        color: var(--palette-gray-s0-l50);
      }

      &__radio {
        display: flex;
        align-items: center;
        gap: 1.2rem;
      }
    }

    &__error {
      color: var(--palette-red-s60-l60);
      font-size: 1.2rem;
      display: inline;
      padding: 0;
    }

    &__div-error {
      padding-bottom: 1.2rem;
    }

    &__documentInput {
      padding-bottom: 1.6rem;
    }
  }

  .TextInput__input:focus {
    box-shadow: none;
  }
`;

export default UserVerifyModalStyled;
