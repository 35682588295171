import { Modal } from "artisn-ui-react";
import styled from "styled-components";

import { PrivacyModalStyledProps as Props } from "./PrivacyModal.types";

const PrivacyModalStyled = styled(Modal)<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;

  .PrivacyModal {
    &__title {
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 3.2rem;
      padding-bottom: 2rem;
    }

    &__paragraph {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6rem;
      padding-bottom: 3.2rem;

      &:nth-child(3) {
        border-bottom: 1px solid var(--palette-gray-s0-l50);
        margin-bottom: 3.2rem;
      }
    }

    &__checkbox {
      padding-bottom: 1.6rem;
    }

    &__terms {
      font-size: 1.6rem;
      color: var(--palette-gray-s80);
      line-height: 1.6rem;

      & > a {
        color: var(--palette-primary-s80);
        text-decoration: underline;
        font-weight: 500;
      }
    }

    &__error-message {
      margin-bottom: 1.2rem;
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
    }

    &__button {
      width: 100%;
      margin: 0 auto;
    }
  }
`;

export default PrivacyModalStyled;
