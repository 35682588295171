import React from "react";

import Styles from "./ClosedStoreNotification.styles";
import { ClosedStoreNotificationProps as Props } from "./ClosedStoreNotification.types";
import Button from "components/global/Button/Button";
import { CONSTANTS } from "config/constants";
import { defaultFunction } from "utils/common.utils";

import StoreSVG from "../../../../../public/assets/images/store.svg";

const { WITH_PURCHASE } = CONSTANTS.FEATURE_FLAGS;

const ClosedStoreNotification: React.FC<Props> = props => {
  const { className, catalogue, opened = true } = props;
  const { onButton, onClose = defaultFunction } = props;

  if (!WITH_PURCHASE) return null;

  return (
    <Styles
      className={`ClosedStoreNotification ${className}`}
      opened={opened}
      onClose={onClose}
    >
      <div className="ClosedStoreNotification__icon">
        <StoreSVG viewBox="0 0 18 18" />
      </div>
      <p className="ClosedStoreNotification__title">Tienda cerrada</p>
      <p className="ClosedStoreNotification__description">
        Intenta volver luego, para realizar tu compra
      </p>
      <Button
        type="BORDER"
        className="ClosedStoreNotification__button ClosedStoreNotification__button--desktop"
        onClick={onButton}
      >
        {catalogue === "DELIVERY" ? "Cambiar dirección" : "Cambiar tienda"}
      </Button>
      <Button
        type="BORDER"
        className="ClosedStoreNotification__button ClosedStoreNotification__button--mobile"
        onClick={onButton}
      >
        cambiar
      </Button>
    </Styles>
  );
};

ClosedStoreNotification.defaultProps = {
  className: ""
};

export default ClosedStoreNotification;
