import { useContext } from "react";

import { ModalsContext } from "./modals.context";
import { ModalsProviderValue } from "./modals.context.types";

const useModals = () => {
  const context = useContext<ModalsProviderValue>(ModalsContext);
  if (typeof context === "undefined") {
    throw new Error("useModals must be used within a ModalsProvider");
  }
  return context;
};

export default useModals;
