import { opacify } from "polished";
import { css } from "styled-components";

import { CONSTANTS } from "config/constants";
import variables from "styles/util/variables";
import { getMaxHeight } from "utils/styling.utils";

const { mobile, tablet } = CONSTANTS.BREAKPOINTS;
const primary = variables.palette["purple-s85-l45"];

const base = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    scroll-behavior: smooth;
    scroll-padding-top: 10rem;
  }

  html {
    font-size: 62.5%; //16px default to 10px
  }

  body {
    box-sizing: border-box;
    background-color: var(--palette-gray-s0-l98);
    max-width: 100%;
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }

  :is(.TabsMenu__page, .Layout) > * {
    grid-template-rows: max-content 1fr max-content;
  }

  .fieldset {
    border: none;
  }

  .SettingsDrawer {
    &__separator {
      height: 0.8rem;
      background-color: var(--palette-gray-s0-l98);
      margin-left: -2.4rem;
      margin-right: -2.4rem;
    }

    &__info-card {
      padding: 1.6rem 0;
    }

    &__drawer {
      &__header {
        position: relative;
        padding-bottom: 4rem;

        &__title {
          text-align: center;
          font-family: Inter;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }

        &__cross {
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }
      }

      &__buttonGroupText {
        font-family: Inter;
        font-size: 1.2rem;
        line-height: 2rem;
        color: var(--palette-gray-s0-l40);
      }

      &__button {
        margin-top: auto;
        display: flex;
        justify-content: center;
        padding: 1rem 1.6rem;
        background: var(--palette-black-s0-l10);
        border-radius: 0.4rem;
        border: none;
        cursor: pointer;

        &__text {
          font-family: Inter;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: var(--palette-white);
        }
      }
    }

    &__buttonGroup {
      padding-top: 0.8rem;
      padding-bottom: 2.4rem;

      .Button--active {
        border: 0.1rem solid var(--palette-primary) !important;
        color: var(--palette-primary);
      }

      &__button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.6rem 2.4rem;
        border: 0.1rem solid var(--palette-gray-s0-l80);
        width: 11.2rem;
        height: 4.8rem;
        font-size: 1.6rem;

        &:first-child {
          border-top-left-radius: 0.4rem;
          border-bottom-left-radius: 0.4rem;
        }

        &:last-child {
          border-top-right-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
        }
      }
    }
  }

  .SearchBar__item--active {
    background-color: var(--palette-gray-s0-l98);
  }

  .CheckoutOrderStatus {
    &__modal {
      width: 100%;
      height: ${getMaxHeight};
    }
  }

  .SearchBar__wrapper,
  .SearchBar__btn {
    border-radius: 0.8rem;
  }

  .SearchBar__wrapper > .SearchBar__btn.SearchBar__btn-search {
    padding-left: 1.2rem;
    width: 3.6rem;
  }

  .Category,
  .Products {
    &__drawer {
      width: 100%;
      height: 100%;
      padding-top: 1.6rem;

      &__close {
        position: absolute;
        top: 2.4rem;
        right: 2rem;
        z-index: 2;
        cursor: pointer;
      }
    }

    &__results {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 1.6rem 2.4rem;
      background-color: var(--palette-white);
      z-index: 2;

      &&& &__button {
        font-family: inherit;
        width: 100%;
        justify-content: center;
        padding: 1.8rem 2.4rem;
      }
    }
  }

  .field {
    &&& label {
      color: var(--palette-gray-s0-l35);
      padding-bottom: 0.4rem;
      font-size: 1.2rem;

      &.Checkbox__label {
        padding-bottom: 0;
      }
    }

    &&& input {
      padding: 1.2rem 1.6rem;
      font-size: 1.6rem;
      color: var(--palette-black-s0-l10);
      background-color: var(--palette-white);
      border: 0.1rem solid var(--palette-gray-s0-l75);
      font-weight: 600;
      border-radius: 1.2rem;
      outline: none;
      line-height: 3rem;

      @media (max-width: ${mobile}px) {
        border: 0.1rem solid var(--palette-gray-s0-l90);
      }

      &:hover {
        z-index: 1;
        border-color: var(--palette-primary);
      }

      &::placeholder {
        color: var(--palette-gray-s0-l80);
      }

      &:focus {
        z-index: 1;
        border-color: var(--palette-primary);
        box-shadow: 0px 0px 0px 0.1rem ${opacify(-0.8, primary)};
      }

      &.PhoneNumberInput__input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:disabled {
        background-color: var(--palette-gray-s0-l90);
        color: var(--palette-gray-s0-l50);
        border-color: transparent;
      }
    }

    &&& .PasswordInput {
      &__container {
        border: unset;
        box-shadow: unset;
      }
    }

    .TextInput__input {
      &:focus {
        box-shadow: none;
      }
    }

    &&& figure {
      z-index: 1;
    }

    &&& .SelectCountry {
      background-color: var(--palette-gray-s0-l98);
      border-top-left-radius: 1.2rem;
      border-bottom-left-radius: 1.2rem;

      &:hover {
        border-color: var(--palette-primary);

        & > * {
          border-color: var(--palette-primary);
        }
      }

      &__flag {
        border-color: var(--palette-gray-s0-l95);
      }

      &__select {
        border-color: var(--palette-gray-s0-l95);
      }
    }

    &&& .DocumentTypeSelect__selectContainer {
      background-color: var(--palette-gray-s0-l98);
      border-color: var(--palette-gray-s0-l95);

      &:hover {
        border-color: var(--palette-primary);
      }

      &:focus-within {
        border-color: var(--palette-primary);
        box-shadow: 0px 0px 0px 0.1rem ${opacify(-0.8, primary)};
      }
    }
  }

  .border__item {
    border: 0.1rem solid var(--palette-black-s0-l10) !important;
    display: grid !important;
    grid-template-columns: max-content 1fr max-content;
    align-items: center !important;
    padding: 1.2rem 1.6rem !important;
    min-height: 6.4rem !important;
    border-radius: 0.4rem !important;

    &--inactive {
      border: 0.1rem solid var(--palette-gray-s0-l95) !important;
    }
  }

  .loading-shine {
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      background: linear-gradient(
        to right,
        transparent,
        rgba(180, 180, 180, 0.3),
        transparent
      );
      animation: load 1.5s cubic-bezier(0.4, 0, 0.3, 1) infinite;
      position: absolute;
      left: -100%;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  div.SettingsDrawer__drawer {
    padding: 1.6rem 2.4rem;
    padding-top: calc(1.6rem + env(safe-area-inset-top));
    min-width: 40rem;
    min-height: var(--sizes-page-maxHeight);
    max-height: calc(var(--sizes-page-maxHeight) - 5.7rem);

    @media (hover: none) {
      width: var(--sizes-page-maxWidth);
      min-height: calc(var(--sizes-page-maxHeight) - 5.7rem);
    }
  }

  .SettingsDrawer {
    &__buttonGroup {
      &__button {
        flex: 1;
      }
    }
  }

  .NotificationsContainer {
    & &__container {
      @media (max-width: ${tablet}px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        right: 0.6rem;
        left: 0.6rem;
        transform: translate(0, -10%);
      }
    }
  }

  .Backdrop {
    @media (max-width: ${tablet}px) {
      background-color: var(--palette-white-s0-l0-a40) !important;
    }
  }

  .DropdownOverlay {
    position: fixed;
    background-color: var(--palette-primary);
    border-radius: 2.4rem;
    overflow: hidden;
    margin-top: 1.6rem;
    box-shadow: 0rem 0.4rem 1.6rem 0rem #3333330f;

    @media (max-width: ${tablet}px) {
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-top: 0;
    }
  }

  .UpdateAppModal,
  .OutOfServiceModal {
    &__backdrop {
      height: 100vh !important;
    }
  }

  .VictoryContainer {
    > svg {
      overflow: visible;
    }
  }

  .Pocket__header__title {
    font-size: 2rem;
  }

  .InvestFormButton__modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62rem;
    border-radius: 1.6rem !important;
    overflow: hidden;
  }

  .Backdrop > .Modal {
    border-radius: 2.4rem;
    overflow: hidden;
  }

  .Modal__body {
    width: 100%;
    background-color: var(--palette-gray-s0-l97);
    padding: 4.8rem;

    @media (max-width: ${tablet}px) {
      padding: 2rem;
    }
  }

  .InvestFormButton__modal {
    @media (max-width: ${mobile}px) {
      border-radius: 0 !important;
    }
  }

  .InvestFormButton {
    &__title {
      font-size: 2.8rem;
      font-weight: 600;
      padding-bottom: 3.4rem;
    }

    &__buttonsContainer {
      background-color: var(--palette-white);
      padding: 3.4rem;
      border-radius: 2.4rem;

      @media (max-width: ${mobile}px) {
        align-items: center;
        justify-content: center;
      }
    }

    &__naturalRoute {
      border-bottom: 0.1rem solid var(--palette-gray-s0-l90);
      border-radius: 0 !important;
      padding-right: 10rem !important;
      padding-bottom: 2rem !important;
    }

    &__legalRoute {
      padding-top: 2rem !important;
    }
  }

  .Select__dropdown {
    color: var(--palette-gray-s0-l50);
    font-size: 1.6rem;
  }

  .radioContainer {
    display: flex;
    align-items: center;
    padding-top: 1.6rem;
    flex-wrap: wrap;
    border: none;

    div {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      padding-right: 1.6rem;

      @media (max-width: ${tablet}px) {
        padding-bottom: 0.8rem;
      }

      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        border: 0.1rem solid var(--palette-gray-s0-l70-a48);
        transition: 0.2s all linear;
        margin-right: 0.5rem;
        position: relative;

        &:checked {
          border: 0.6rem solid var(--palette-primary);
        }

        &:disabled {
          background-color: var(--palette-gray-s0-l70-a48);
        }
      }

      label {
        font-size: 1.6rem;
        padding-left: 0.4rem;
        user-select: none;
      }
    }

    .errorMessage {
      display: inline-block;
      padding-top: 0.4rem;
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
    }
  }

  .radio {
    font-weight: 600;
    padding-top: 0;

    &__disabled {
      color: var(--palette-gray-s0-l70);
    }
  }

  .DateInput__label {
    color: var(--palette-black);
    font-size: 1.2rem;
    font-weight: 400;
    padding-left: 1rem;
  }

  .DateInput__input {
    color: var(--palette-gray-s0-l50);
    font-size: 1.6rem;
    font-weight: 400;
    padding: 0.6rem 1.6rem;
    border-radius: 1.2rem;
  }

  .DateInput__errorMessage {
    padding-left: 1rem;
  }

  .Select__label {
    padding-left: 1rem;
  }

  .Select__error {
    padding-left: 1rem;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export default base;
