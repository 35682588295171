// Initialize ShoppingCart
import { useStores } from "@anefi/contexts";
import { useShoppingCart } from "@anefi/contexts";
import { useCatalogues } from "@anefi/contexts";
import { initShoppingCart } from "artisn/shopping-cart";
import { listenShoppingCart } from "artisn/shopping-cart";
import { closeShoppingCart } from "artisn/shopping-cart";
import { useEffect } from "react";

import { artisn, auth } from "config/artisn.config";
import { CONSTANTS } from "config/constants";

const { ACCOUNT_ID, SHOPPING_CART_WISHLIST_NAME } = CONSTANTS;

const useSetupShoppingCart = () => {
  const { setShoppingCart, setWishlist } = useShoppingCart();
  const { setIsShoppingCartInit } = useShoppingCart();
  const { catalogueId } = useCatalogues().selectedCatalogue;
  const { selectedStore } = useStores();

  const customerId = auth()?.currentUser?.uid;

  useEffect(() => {
    if (!customerId) {
      return;
    }
    // initialize shopping cart
    initShoppingCart(artisn, {
      accountId: ACCOUNT_ID,
      customerId,
      store: selectedStore
    });
    // start listenting wishlist cart changes
    listenShoppingCart(
      cart => {
        // If new cart is created, store it
        setWishlist(cart);
      },
      {
        shoppingCartName: SHOPPING_CART_WISHLIST_NAME
      }
    );
    // start listenting cart changes
    listenShoppingCart(cart => {
      // If new cart is created, store it
      setShoppingCart(cart);
      setIsShoppingCartInit(true);
    });

    // If useEffect rerenders ensures it closes the old shoppingCart
    return closeShoppingCart;
  }, [
    customerId,
    setShoppingCart,
    catalogueId,
    setIsShoppingCartInit,
    setWishlist,
    selectedStore
  ]);
};

export default useSetupShoppingCart;
