import styled from "styled-components";

import { SuccessNotificationStyledProps as Props } from "./SuccessNotification.types";
import Notification from "components/global/notifications/Notification/Notification";

const SuccessNotificationStyled = styled(Notification)<Props>`
  display: grid;
  grid-template-columns: 3.2rem auto;
  padding: 1.2rem;
  column-gap: 1.6rem;
  align-items: center;
  border: 0.1rem solid var(--palette-green-s63-l42);
  background-color: var(--palette-white);
  border: unset;
  margin-top: 8rem;
  animation: AppearAbove 0.3s;

  .SuccessNotification {
    &__title {
      color: var(--palette-primary);
      font-weight: 600;
      line-height: 2rem;
      font-size: 1.6rem;
    }

    &__message {
      font-size: 1.4rem;
      color: var(--palette-black);
      line-height: 2rem;
      grid-column: 2/3;
    }
  }
`;

export default SuccessNotificationStyled;
