import { AnefiDocumentType } from "@anefi/types";
import * as yup from "yup";

import { validateDocument } from "../utils/validation.utils";

yup.addMethod<yup.StringSchema>(
  yup.string,
  "validateAnefiDocument",
  function (documentTypeCode: AnefiDocumentType) {
    return this.test(`test-document`, "", function (value) {
      const { path, createError } = this;

      if (!value) return false;

      const documentValidation = validateDocument(
        "EC",
        documentTypeCode,
        value
      );

      if (!documentValidation) return true;

      return createError({ path, message: documentValidation });
    });
  }
);

declare module "yup" {
  interface StringSchema {
    validateAnefiDocument(documentTypeCode: AnefiDocumentType): StringSchema;
  }
}
