import { Button as ButtonUI } from "artisn-ui-react";
import styled, { css } from "styled-components";

import { ButtonColor, ButtonStyledProps as Props } from "./Button.types";

const mapType = (props: Props) => {
  const { type, color } = props;
  switch (type) {
    case "FILLED":
      return mapColorByFilledType(color);
    case "BORDER":
      return mapColorByBorderType(color);
    case "LINK":
      return mapColorByLinkType(color);
    default:
      return mapColorByFilledType(color);
  }
};

const mapColorByFilledType = (color?: ButtonColor) => {
  switch (color) {
    case "primary":
      return css`
        background-color: var(--palette-primary);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-primary);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
            border: 0.1rem solid var(--palette-primary);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s0-l80);
          color: var(--palette-white);
          box-shadow: none;
        }
      `;

    case "white":
      return css`
        background-color: var(--palette-white);
        color: var(--palette-primary);
        border: none;

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-gray-s0-l90);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-primary),
              0rem 0.4rem 1.6rem var(--palette-primary);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s0-l80);
          color: var(--palette-white);
          box-shadow: none;
        }
      `;

    case "primary-light":
      return css`
        border: 0.1rem solid var(--palette-gray-s0-l90);
        background-color: var(--palette-gray-s0-l90);
        color: var(--palette-primary);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-gray-s0-l80);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
            border: 0.1rem solid var(--palette-gray-s0-l80);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s0-l80);
          color: var(--palette-white);
          box-shadow: none;
        }
      `;

    case "black":
      return css`
        border: 0.1rem solid var(--palette-black);
        background-color: var(--palette-black);
        color: var(--palette-white);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-gray-s0-l20);
            border: 0.1rem solid var(--palette-gray-s0-l20);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s0-l70);
          color: var(--palette-white);
          box-shadow: none;
        }
      `;

    default:
      return css`
        background-color: var(--palette-primary);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-main-s15-l30);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
            border: 0.1rem solid var(--palette-main-s15-l30);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s0-l80);
          color: var(--palette-white);
          box-shadow: none;
        }
      `;
  }
};

const mapColorByBorderType = (color?: ButtonColor) => {
  switch (color) {
    case "primary":
      return css`
        border: 0.2rem solid var(--palette-primary);
        color: var(--palette-primary);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-primary);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
            border: 0.2rem solid var(--palette-primary);
            color: var(--palette-white);
          }
        }

        &:disabled {
          border: 0.2rem solid var(--palette-gray-s0-l80);
          color: var(--palette-gray-s0-l80);
          box-shadow: none;
        }
      `;

    case "primary-light":
      return css`
        border: 0.2rem solid var(--palette-primary-light);
        color: var(--palette-primary-dark);

        &:hover {
          &:not([disabled]) {
            color: var(--palette-primary);
          }
        }

        &:disabled {
          border: 0.2rem solid var(--palette-primary-light);
          color: var(--palette-gray-s0-l80);
          box-shadow: none;
        }
      `;

    case "secondary":
      return css`
        border: 0.1rem solid var(--palette-gray-s0-l90);
        background-color: transparent;
        color: var(--palette-secondary-s40-l45);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-secondary-s40-l45);
            color: var(--palette-white);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
            border: 0.1rem solid var(--palette-gray-s0-l80);
          }
        }

        &:disabled {
          background-color: var(--palette-gray-s0-l80);
          color: var(--palette-white);
          box-shadow: none;
        }
      `;

    case "black":
      return css`
        border: 0.2rem solid var(--palette-black);
        color: var(--palette-black);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-gray-s0-l20);
            color: var(--palette-white);
            border: 0.2rem solid var(--palette-black);
          }
        }

        &:disabled {
          color: var(--palette-gray-s0-l70);
          box-shadow: none;
        }
      `;

    default:
      return css`
        border: 0.2rem solid var(--palette-primary);
        color: var(--palette-primary);

        &:hover {
          &:not([disabled]) {
            background-color: var(--palette-primary);
            box-shadow: 0rem 0.4rem 0.4rem var(--palette-gray-s0-l95),
              0rem 0.4rem 1.6rem var(--palette-gray-s0-l92);
            border: 0.2rem solid var(--palette-primary);
            color: var(--palette-white);
          }
        }

        &:disabled {
          border: 0.2rem solid var(--palette-gray-s0-l80);
          color: var(--palette-gray-s0-l80);
          box-shadow: none;
        }
      `;
  }
};

const mapColorByLinkType = (color?: ButtonColor) => {
  switch (color) {
    case "primary":
      return css`
        color: var(--palette-secondary-s41-l43);

        &:hover {
          &:not([disabled]) {
            color: var(--palette-primary);
          }
        }

        &:disabled {
          color: var(--palette-primary);
          box-shadow: none;
        }
      `;

    case "black":
      return css`
        color: var(--palette-black);

        &:hover {
          &:not([disabled]) {
            color: var(--palette-gray-s0-l20);
          }
        }

        &:disabled {
          box-shadow: none;
        }
      `;

    default:
      return css`
        color: var(--palette-secondary-s40-l45);

        &:hover {
          &:not([disabled]) {
            color: var(--palette-secondary-s100-l35);
          }
        }

        &:disabled {
          color: var(--palette-secondary-s100-l35);
          box-shadow: none;
        }
      `;
  }
};

const ButtonStyled = styled(ButtonUI)<Props>`
  &.Button {
    white-space: nowrap;
    display: flex;
    box-shadow: none;
    outline: none;
    background-color: none;
    border-radius: 10rem;
    cursor: pointer;
    padding: 1rem 2rem;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9;
    ${mapType}
  }

  .Button {
  }
`;

export default ButtonStyled;
