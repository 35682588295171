// UserVerifyModal helper functions and data
import { DocumentType } from "artisn/types";
import { AnefiDocumentType } from "@anefi/types";
import * as yup from "yup";

import { validationRules, documentTypeValidator } from "utils/form.utils";
import { DocumenTypeCodes } from "./UserVerifyModal.types";

const { requiredDocumentType } = validationRules;

export const userVerifyForm = (
  documentTypeCode: AnefiDocumentType | undefined
) =>
  yup.object().shape({
    documentTypeCode: requiredDocumentType,
    document: documentTypeCode
      ? documentTypeValidator(documentTypeCode)
      : yup.string().required("Documento requerido")
  });

export const inputDocumentTypeCodes: DocumenTypeCodes[] = [
  { label: "Cédula", value: "1" },
  { label: "RUC", value: "2" }
];

export const defaultValuesForm = {
  documentTypeCode: undefined,
  document: undefined
};

export const mapDocumentArtisnTypeToAnefiType = (
  documentType: DocumentType
) => {
  switch (documentType) {
    case "CI":
      return "1";
    case "RUC":
      return "2";
    case "PASSPORT":
      return "3";
  }
};

export const mapDocumentAnefiTypeToArtisnType = (
  documentType: AnefiDocumentType
) => {
  switch (documentType) {
    case "1":
      return "CI";
    case "2":
      return "RUC";
    case "3":
      return "PASSPORT";
  }
};
