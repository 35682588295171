import { useCountries } from "@anefi/contexts";
import Bugsnag from "@bugsnag/js";
import { Capacitor } from "@capacitor/core";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";
import { StatusBar, Style } from "@capacitor/status-bar";
import Head from "next/head";
import React, { memo, useEffect, useState } from "react";

import Styles from "./Layout.styles";
import { LayoutProps as Props } from "./Layout.types";
import MergeCartModal from "components/global/MergeCartModal/MergeCartModal";
import OutOfServiceModal from "components/global/OutOfServiceModal/OutOfServiceModal";
import ShareCartModal from "components/global/ShareCartModal/ShareCartModal";
import UpdateAppModal from "components/global/UpdateAppModal/UpdateAppModal";
import { getENVs, firestore } from "config/artisn.config";
import { CONSTANTS } from "config/constants";
import useSetupArtisn from "hooks/artisn/useSetupArtisn";
import useListeners from "hooks/useListeners";
import variables from "styles/util/variables";
import UserVerifyModal from "../UserVerifyModal/UserVerifyModal";
import PrivacyModal from "components/modals/PrivacyModal/PrivacyModal";

const { TITLE, FEATURE_FLAGS, ACCOUNT_ID, APP } = CONSTANTS;
const { WITH_SHARE_SHOPPING_CART } = FEATURE_FLAGS;
const { WITH_ANONYMOUS_SHOPPING_CART_TOKEN } = FEATURE_FLAGS;
const { APPLE_STORE_URL, PLAY_STORE_URL, WEB_URL } = APP;
const VERSION_CODE = CONSTANTS.VERSION_CODE.toString();

const Layout: React.FC<Props> = props => {
  const { children } = props;
  useSetupArtisn();
  useListeners();
  const { locale } = useCountries().selectedCountry;
  const [remoteVersionCode, setRemoteVersionCode] = useState("");
  const [updateApp, setUpdateApp] = useState(false);

  const isIOS = Capacitor.getPlatform() === "ios";
  const isAndroid = Capacitor.getPlatform() === "android";
  const isMobile = isIOS || isAndroid;

  useEffect(() => {
    if (isIOS) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }

    if (isMobile) {
      StatusBar.setStyle({ style: Style.Light });
    }

    if (isAndroid) {
      StatusBar.setBackgroundColor({ color: variables.palette["white-hex"] });
    }
  }, [isAndroid, isIOS, isMobile]);

  useEffect(() => {
    (async () => {
      try {
        if (
          isMobile &&
          remoteVersionCode &&
          remoteVersionCode !== "0" &&
          remoteVersionCode !== VERSION_CODE
        ) {
          const semver = await import("semver");
          setUpdateApp(semver.default.gte(remoteVersionCode, VERSION_CODE));
          return;
        }
        setUpdateApp(false);
      } catch (e) {
        Bugsnag.notify({
          ...e,
          name: "Remote version code"
        });
        console.error(e);
      }
    })();
  }, [isMobile, remoteVersionCode]);

  useEffect(() => {
    firestore
      ?.collection("appParameters")
      .doc(`${ACCOUNT_ID}`)
      .onSnapshot(doc => {
        setRemoteVersionCode(doc.data()?.versionCode);
      });
  }, []);

  return (
    <Styles className="Layout">
      <Head>
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
        <script
          defer
          src={`https://maps.googleapis.com/maps/api/js?key=${getENVs?.mapsApiKey}&libraries=places&language=${locale}`}
        />
        <title>{TITLE}</title>
      </Head>
      {children}
      {WITH_SHARE_SHOPPING_CART ? <ShareCartModal /> : null}
      {WITH_ANONYMOUS_SHOPPING_CART_TOKEN ? <MergeCartModal /> : null}
      {isMobile && remoteVersionCode === "0" ? (
        <OutOfServiceModal url={WEB_URL} />
      ) : null}
      {updateApp ? (
        <UpdateAppModal storeUrl={isIOS ? APPLE_STORE_URL : PLAY_STORE_URL} />
      ) : null}
      {<UserVerifyModal />}
      <PrivacyModal />
    </Styles>
  );
};

Layout.defaultProps = {};

export default memo(Layout);
