// Form utility functions
import { birthdateMaximumDate } from "@anefi/utils";
import { DocumentType } from "artisn/types";
import * as yup from "yup";
import { AnefiDocumentType } from "@anefi/types";

import { StepOneFormValues } from "components/checkout/Checkout/NaturalPath/StepOneForm/StepOneForm.types";
import { FormShape } from "types/common.types";
import { StepTwoFormValues } from "components/checkout/Checkout/NaturalPath/StepTwoForm/StepTwoForm.types";
import { StepThreeFormValues } from "components/checkout/Checkout/NaturalPath/StepThreeForm/StepThreeForm.types";
import { StepFourFormValues } from "components/checkout/Checkout/NaturalPath/StepFourForm/StepFourForm.types";
import { IndependentFormValues } from "components/checkout/Checkout/NaturalPath/StepFiveForm/IndependentForm/IndependentForm.types";
import { EmployeeFormValues } from "components/checkout/Checkout/NaturalPath/StepFiveForm/EmployeeForm/EmployeeForm.types";
import { StepSixFormValues } from "components/checkout/Checkout/NaturalPath/StepSixForm/StepSixForm.types";
import { StepSevenFormValues } from "components/checkout/Checkout/NaturalPath/StepSevenForm/StepSevenForm.types";
import { StepEightFormValues } from "components/checkout/Checkout/NaturalPath/StepEightForm/StepEightForm.types";
import { StepTwoLegalFormValues } from "components/checkout/Checkout/LegalPath/StepTwoLegalForm/StepTwoLegalForm.types";
import { StepOneLegalFormValues } from "components/checkout/Checkout/LegalPath/StepOneLegalForm/StepOneLegalForm.types";
import { StepThreeLegalFormValues } from "components/checkout/Checkout/LegalPath/StepThreeLegalForm/StepThreeLegalForm.types";
import { ConfirmDeleteAccountFormValues } from "components/profile/unsubscribe/Confirm/Confirm.types";

export const validationMessages = {
  required: "Campo requerido",
  invalidEmail: "Correo electrónico no válido",
  noMatchesEmail: "El correo electrónico no coincide",
  minLength: (min: number) => `El campo debe tener ${min} caracteres mínimo`,
  maxLength: (max: number) => `El campo debe tener ${max} caracteres máximo`,
  onlyNumbers: "El campo debe contener solo números",
  invalidPassword: "Contraseña inválida"
};

const { invalidEmail, required, maxLength, minLength } = validationMessages;
const { onlyNumbers, invalidPassword, noMatchesEmail } = validationMessages;

export const validationRules = {
  requiredDocumentType: yup.string().required(required),
  email: yup
    .string()
    .email(invalidEmail)
    .min(3, minLength(3))
    .max(50, maxLength(50)),
  confirmEmailValidator: (emailSaved: string) =>
    yup
      .string()
      .required(required)
      .email(invalidEmail)
      .oneOf([emailSaved], noMatchesEmail),
  requiredString: yup
    .string()
    .required(required)
    .min(3, minLength(3))
    .max(50, maxLength(50))
    .nullable(),
  requiredNumber: yup
    .number()
    .typeError(onlyNumbers)
    .required(required)
    .min(3, minLength(3))
    .max(50, maxLength(50)),
  requiredBoolean: yup.bool().oneOf([true], required),
  requiredEmail: yup
    .string()
    .required(required)
    .email(invalidEmail)
    .min(3, minLength(3))
    .max(50, maxLength(50)),
  requiredPhoneNumber: yup
    .string()
    .required(required)
    .matches(/^[0-9]+$/, onlyNumbers)
    .test(
      "len",
      "El campo debe tener 10 caracteres",
      value => value?.toString().length === 10
    ),
  customPhoneNumber: (min: number, max: number) =>
    yup
      .string()
      .required(required)
      .matches(/^[0-9]+$/, onlyNumbers)
      .min(min, minLength(min))
      .max(max, maxLength(max)),
  minLength: (min: number) =>
    yup.string().required(required).min(min, minLength(min)),
  maxLength: (max: number) =>
    yup.string().required(required).max(max, maxLength(max)),
  customName: (min: number) =>
    yup
      .string()
      .required(required)
      .min(min, minLength(min))
      .max(50, maxLength(50))
      .matches(
        /^[^0-9_!¡?÷?¿/\\+=@#$%^&*(){}|~<>;:[\]]{2,}$/i,
        "El campo debe tener solo letras"
      ),
  password: yup
    .string()
    .required(required)
    .min(7, minLength(7))
    .max(70, maxLength(70))
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#^<>;,:.'_@$!%*&"¡¿?°/()=|+~{}-]).{7,70}$/,
      invalidPassword
    ),
  requiredBillingAddress: yup
    .string()
    .required(required)
    .min(3, minLength(3))
    .nullable(),
  twoOrMoreWords: (message?: string) =>
    yup
      .string()
      .required(required)
      .matches(/^[^\d]+$/, "El campo no debe contener números")
      .matches(
        /^[a-zA-Z\u00C0-\u1FFF]+\s+([a-zA-Z\u00C0-\u1FFF]+\s?)+/,
        message ?? "El campo debe contener mínimo dos palabras"
      ),
  documentRules: (documentType: DocumentType | undefined) => {
    switch (documentType) {
      case "CI":
        return yup
          .string()
          .required(required)
          .matches(/^\d+$/, onlyNumbers)
          .test("len", "El campo debe tener 10 caracteres", value => {
            return value?.length === 10;
          })
          .nullable();
      case "RUC":
        return yup
          .string()
          .required(required)
          .matches(/^\d+$/, onlyNumbers)
          .test(
            "len",
            "El campo debe tener 13 caracteres",
            value => value?.length === 13
          )
          .nullable();
      default:
        return yup
          .string()
          .required(required)
          .min(1, minLength(1))
          .max(20, maxLength(20))
          .nullable();
    }
  },
  nameValidator: () =>
    yup
      .string()
      .nullable()
      .required("Campo requerido")
      .max(50, "El campo debe tener %{max} caracteres máximo")
      .min(2, "El campo debe tener %{min} caracteres mínimo")
      .matches(/^[a-záéíóúñA-ZÁÉÍÓÚÑ0-9 ]*$/, {
        message: "Solo se permiten letras y números"
      }),
  matchEmailValidator: (emailCompare: string) => {
    return yup
      .string()
      .required(required)
      .email(invalidEmail)
      .oneOf([emailCompare], noMatchesEmail);
  }
};

export const trimFields = <T>(obj: T): T => {
  if (!obj) return obj;
  return JSON.parse(JSON.stringify(obj).replace(/"\s+|\s+"/g, '"'));
};

export const getMaxLength = (documentType: string | undefined) => {
  switch (documentType) {
    case "CI":
      return 10;
    case "RUC":
      return 13;
    default:
      return 20;
  }
};

export const stringValidator = (min = 2, max = 50) =>
  yup
    .string()
    .nullable()
    .required(required)
    .max(max, maxLength(max))
    .min(min, minLength(min));

export const transferBankSchema = () => {
  return yup.object().shape({
    paymentDocument: yup
      .string()
      .required(required)
      .max(50, maxLength(50))
      .min(2, minLength(2))
      .matches(/^[a-zA-Z0-9_.-]*$/, "Solo se permiten letras y números"),
    effectiveDate: yup.string().required(required)
  });
};

export const getBankAccountSchema = () =>
  yup.object().shape({
    financialInstitutionCode: yup.string().required(required),
    account: stringValidator(),
    accountTypeCode: yup.string().required(required)
  });

export const periodSchema = () =>
  yup.object().shape({
    periodType: yup.string().min(1, required).required(required),
    periodValue: yup
      .number()
      .typeError("El campo debe contener solo números")
      .min(1, "El campo debe ser mayor a 0")
      .required(required)
  });

export const documentTypeValidator = (documentTypeCode: AnefiDocumentType) =>
  yup.string().validateAnefiDocument(documentTypeCode).required(required);

export const phoneValidator = () =>
  yup
    .string()
    .nullable()
    .required("Campo requerido")
    .min(9, "El campo debe tener 9 caracteres mínimo")
    .max(10, "El campo debe tener 10 caracteres máximo");

export const homePhoneValidator = () =>
  yup
    .string()
    .nullable()
    .required("Campo requerido")
    .min(8, "El campo debe tener 8 caracteres mínimo")
    .max(9, "El campo debe tener 9 caracteres máximo");

export const fingerprintValidator = () =>
  yup
    .string()
    .nullable()
    .required("Campo requerido")
    .length(10, "El campo debe tener 10 caracteres")
    .matches(/^[a-zA-Z0-9]*$/, { message: "Código dactilar inválido" });

export const numberValidator = () =>
  yup
    .number()
    .required("Campo requerido")
    .typeError("Campo requerido")
    .min(0, "Ingrese un valor correcto");

const { nameValidator } = validationRules;

export const getStepOneSchema = (
  documentTypeCode?: AnefiDocumentType | undefined
) => {
  return yup.object().shape<FormShape<StepOneFormValues>>({
    firstName: nameValidator(),
    secondName: yup
      .string()
      .nullable()
      .max(50, "El campo debe tener 50 caracteres máximo")
      .matches(/^[a-záéíóúñA-ZÁÉÍÓÚÑ0-9 ]*$/, {
        message: "Solo se permiten letras y números"
      }),
    firstLastname: nameValidator(),
    secondLastname: nameValidator(),
    birthdate: yup
      .date()
      .max(
        birthdateMaximumDate,
        "La fecha de nacimiento no puede ser menor a 18 años"
      )
      .typeError("El campo debe contener una fecha válida")
      .nullable()
      .required("Campo requerido"),
    gender: yup.string().nullable().required("Campo requerido"),
    nationalityCountryCode: yup.string().nullable().required("Campo requerido"),
    homeAddressCountryCode: yup.string().nullable().required("Campo requerido"),
    documentTypeCode: yup.string().nullable().required("Campo requerido"),
    document: documentTypeCode
      ? documentTypeValidator(documentTypeCode)
      : yup.string().nullable().required("Campo requerido"),
    politicallyExposed: yup.string().nullable().required("Campo requerido"),
    phoneTypeCode: yup.string().nullable().required("Campo requerido"),
    email: yup
      .string()
      .required("Campo requerido")
      .email("Correo electrónico no válido"),
    phone: phoneValidator()
  });
};

export const getStepTwoSchema = (showNationality: boolean) => {
  return yup.object().shape<FormShape<StepTwoFormValues>>({
    tributaryResidence: yup.bool().required("Campo requerido"),
    nationality: showNationality
      ? yup.string().required("Campo requerido")
      : yup.string()
  });
};

export const getStepThreeCompleteSchema = (
  documentTypeCode: AnefiDocumentType | undefined
) =>
  yup.object().shape<FormShape<StepThreeFormValues>>({
    maritalStatusCode: yup.string().required("Campo requerido"),
    spouseName: nameValidator(),
    spouseDocumentTypeCode: yup.string().required("Campo requerido"),
    spouseDocument: documentTypeCode
      ? documentTypeValidator(documentTypeCode)
      : yup.string().required("Campo requerido"),
    spouseDependentTypeCode: yup.string().required("Campo requerido")
  });

export const getStepThreeSchema = () =>
  yup.object().shape<FormShape<StepThreeFormValues>>({
    maritalStatusCode: yup.string().required("Campo requerido")
  });

export const getStepFourSchema = () =>
  yup.object().shape<FormShape<StepFourFormValues>>({
    homeAddressProvinceCode: yup.string().required("Campo requerido"),
    homeAddressCantonCode: yup.string().required("Campo requerido"),
    homeAddressMainStreet: stringValidator(2, 100),
    IshomeAddressFiscal: yup.bool().required("Campo requerido"),
    homePhoneType: yup.string().required("Campo requerido"),
    additionalPhone: homePhoneValidator()
  });

export const getIndependentSchema = () =>
  yup.object().shape<FormShape<IndependentFormValues>>({
    economicType: yup.string().required("Campo requerido"),
    businessType: yup.string().required("Campo requerido"),
    businessName: stringValidator(),
    activityTime: stringValidator(),
    businessAddress: stringValidator(2, 100),
    businessPhone: phoneValidator()
  });

export const getEmployeeSchema = () =>
  yup.object().shape<FormShape<EmployeeFormValues>>({
    economicType: stringValidator(),
    employeeInstitution: stringValidator(),
    employeeArea: stringValidator(),
    employeePosition: stringValidator(),
    employeeSeniority: stringValidator(),
    employeeAddress: stringValidator(),
    employeePhone: phoneValidator()
  });

export const getStepSixSchema = () =>
  yup.object().shape<FormShape<StepSixFormValues>>({
    principalIncome: yup.number().required("Campo requerido"),
    otherIncome: yup.number().required("Campo requerido"),
    detailIncome: stringValidator(),
    totalExpenses: yup.number().required("Campo requerido"),
    detailExpenses: stringValidator()
  });

export const getStepSevenSchema = () =>
  yup.object().shape<FormShape<StepSevenFormValues>>({
    businessActivityTypeCode: yup.string().required("Campo requerido"),
    totalIncome: numberValidator(),
    monthlyExpenses: numberValidator(),
    totalAssets: numberValidator(),
    totalLiabilities: numberValidator(),
    totalHeritage: numberValidator(),
    payTaxes: yup.string().required("Campo requerido")
  });

export const getStepEightSchema = () =>
  yup.object().shape<FormShape<StepEightFormValues>>({
    bankAccountfinancialEntityCode: yup.string().required("Campo requerido"),
    bankAccountNumber: stringValidator(),
    bankAccountTypeCode: yup.string().required("Campo requerido")
  });

export const getLegalStepOneSchema = (
  documentTypeCode?: AnefiDocumentType | undefined
) => {
  return yup.object().shape<FormShape<StepOneLegalFormValues>>({
    businessName: yup
      .string()
      .nullable()
      .required("Campo requerido")
      .max(50, "El campo debe tener 50 caracteres máximo"),
    startDate: yup.string().required("Campo requerido"),
    businessActivityTypeCode: yup
      .string()
      .nullable()
      .required("Campo requerido"),
    nationalityCountryCode: yup.string().nullable().required("Campo requerido"),
    documentTypeCode: yup.string().nullable().required("Campo requerido"),
    document: documentTypeCode
      ? documentTypeValidator(documentTypeCode)
      : yup.string().nullable().required("Campo requerido"),
    politicallyExposed: yup.string().nullable().required("Campo requerido"),
    phone: phoneValidator()
  });
};

export const getLegalStepTwoSchema = () => {
  return yup.object().shape<FormShape<StepTwoLegalFormValues>>({
    countryCodeWorkAddress: yup.string().required("Campo requerido"),
    homeAddressProvinceCode: yup.string().required("Campo requerido"),
    orgGeoCodeWorkAddress: yup.string().required("Campo requerido"),
    mainStreetWorkAddress: yup
      .string()
      .required("Campo requerido")
      .max(200, "El campo debe tener 200 caracteres máximo"),
    numberWorkAddress: yup
      .string()
      .required("Campo requerido")
      .max(200, "El campo debe tener 200 caracteres máximo"),
    intersectionWorkAddress: yup
      .string()
      .required("Campo requerido")
      .max(200, "El campo debe tener 200 caracteres máximo"),
    additionalPhone: phoneValidator()
  });
};

export const getLegalStepThreeSchema = () => {
  return yup.object().shape<FormShape<StepThreeLegalFormValues>>({
    totalAssets: numberValidator(),
    totalLiabilities: numberValidator(),
    totalIncome: numberValidator(),
    totalExpenditure: numberValidator()
  });
};

export const confirmDeleteAccountSchema = (emailUser: string) => {
  return yup.object().shape<FormShape<ConfirmDeleteAccountFormValues>>({
    email: validationRules.matchEmailValidator(emailUser)
  });
};
