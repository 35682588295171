import { Providers } from "artisn/analytics";
import { initAuth, getServices } from "artisn/auth";
import { createApp } from "artisn/init";

import { CONSTANTS } from "config/constants";

const { ACCOUNT_ID, PLATFORM, API_URL } = CONSTANTS;

export const getENVs = (() => {
  const env = process.env.NEXT_PUBLIC_ENV ?? "develop";

  return {
    develop: {
      apiKey: "AIzaSyAXYHWFy2RGn3s5sGmPXJZm7nyVHaRyca8",
      authDomain: "anefi-dev.firebaseapp.com",
      databaseURL: "https://anefi-dev-default-rtdb.firebaseio.com",
      projectId: "anefi-dev",
      storageBucket: "anefi-dev.appspot.com",
      messagingSenderId: "1030982123098",
      appId: "1:1030982123098:web:3c423b996ba240afe22263",
      measurementId: "G-FCKKDV21N1",
      // TODO: Change th efollowing api keys
      mapsApiKey: "AIzaSyD-o9htbV86JDu0mMka5nhz3vuYbBR_oAg",
      facebookAppId: "540567017200661"
    },
    production: {
      apiKey: "AIzaSyB8ELmiJshSv2VQU2IxGzDHO88wMq5M8do",
      authDomain: "anefi-prod.firebaseapp.com",
      databaseURL: "https://anefi-prod-default-rtdb.firebaseio.com",
      projectId: "anefi-prod",
      storageBucket: "anefi-prod.appspot.com",
      messagingSenderId: "178576266535",
      appId: "1:178576266535:web:50df9664e21bd516c68502",
      measurementId: "G-5JNJSXVWXC",
      mapsApiKey: "",
      facebookAppId: "1011260033081699"
    }
  }[env];
})();

export const artisn = createApp(
  {
    projectId: getENVs?.projectId ?? "",
    apiKey: getENVs?.apiKey ?? "",
    authDomain: `${getENVs?.projectId}.firebaseapp.com`,
    accountId: ACCOUNT_ID,
    platform: PLATFORM
  },
  API_URL
);

export const auth = () => initAuth(artisn);
export const Services = getServices(artisn);
export const firestore = artisn?.__internals__!.firestore();

export const facebookProvider = new Providers.FacebookPixel({
  name: "facebook",
  trackingId: getENVs?.facebookAppId ?? ""
});

export const googleProvider = new Providers.GoogleAnalytics({
  name: "google",
  trackingId: getENVs?.measurementId ?? ""
});
