// ProductBasePriceInfo helper functions and data

import { CountryMeta } from "@anefi/types";

export const getFormatPointsOption = (country: CountryMeta) => {
  const { currency, locale } = country;
  return {
    currency,
    locale,
    symbol: "pts",
    pointsSymbol: "pts"
  };
};
