import { InvestForm, LegalInvestForm } from "@anefi/contexts";
import { User, Phone } from "artisn-rn/types";

export interface CompleteUser extends User {
  additionalPhone: Phone;
}

export const transformUserToNatural = (
  user: CompleteUser
): Partial<InvestForm> => {
  return {
    ...user?.additionalInfo,
    IshomeAddressFiscal: user?.additionalInfo?.IshomeAddressFiscal === "S",
    additionalPhone: user?.additionalPhone?.number,
    birthdate: user?.birthdate,
    gender: user?.gender,
    phone: user?.phone?.number
  };
};

export const transformUserToLegal = (
  user: CompleteUser
): Partial<LegalInvestForm> => {
  return {
    ...user?.additionalInfo,
    additionalPhone: user?.additionalPhone?.number,
    phone: user?.phone?.number
  };
};
