import React, { createContext, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";

import { ModalsProviderProps as Props } from "./modals.context.types";
import { ModalsProviderValue } from "./modals.context.types";

// @ts-ignore
export const ModalsContext = createContext<ModalsProviderValue>();

const ModalsProvider: React.FC<Props> = props => {
  const [showFirstInvestmentModal, setShowFirstInvestmentModal] =
    useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [validateModalVisible, setValidateModalVisible] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [bankAccountFormVisible, setBankAccountFormVisible] = useState(false);
  const [bankAccountsModalVisible, setBankAccountsModalVisible] =
    useState(false);
  const [userVerifyFormVisible, setUserVerifyFormVisible] = useState(false);
  const [otpModalVisible, setOtpModalVisible] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);

  const value: ModalsProviderValue = useMemo(() => {
    return {
      successModalVisible,
      setSuccessModalVisible,
      errorModalVisible,
      setErrorModalVisible,
      validateModalVisible,
      setValidateModalVisible,
      showLoadingModal,
      setShowLoadingModal,
      bankAccountsModalVisible,
      setBankAccountsModalVisible,
      bankAccountFormVisible,
      setBankAccountFormVisible,
      showFirstInvestmentModal,
      setShowFirstInvestmentModal,
      userVerifyFormVisible,
      setUserVerifyFormVisible,
      otpModalVisible,
      setOtpModalVisible,
      privacyModalVisible,
      setPrivacyModalVisible
    };
  }, [
    successModalVisible,
    errorModalVisible,
    validateModalVisible,
    showLoadingModal,
    bankAccountsModalVisible,
    bankAccountFormVisible,
    showFirstInvestmentModal,
    userVerifyFormVisible,
    otpModalVisible,
    privacyModalVisible
  ]);

  return (
    <ModalsContext.Provider value={value}>
      <ContextDevTool
        context={ModalsContext}
        id="modals"
        displayName="Modals"
      />
      {props.children}
    </ModalsContext.Provider>
  );
};

export default ModalsProvider;
