// Project constants
import { CONSTANTS as SHARED_CONSTANTS } from "@anefi/utils";
import { Capacitor } from "@capacitor/core";
import { ArtisnPlatform } from "artisn/types";

import { PickUpOption } from "types/common.types";

const WITH_PICK_UP = false;
const WITH_DELIVERY = true;
const WITH_PURCHASE = WITH_PICK_UP || WITH_DELIVERY;
const WITH_MULTIPLE_PURCHASE_METHODS = WITH_DELIVERY && WITH_PICK_UP;
const WHATSAPP_PHONE_NUMBER = "+593986117316";

export const DEFAULT_PICK_UP_OPTION = {
  id: 0,
  name: "Quiero mi pedido ahora",
  forNow: true
};

export const PICK_UP_OPTIONS: PickUpOption[] = [
  DEFAULT_PICK_UP_OPTION,
  { id: 1, name: "Hora programada", forNow: false }
];

export const CONSTANTS = {
  ...SHARED_CONSTANTS,
  // CSS Break Points
  BREAKPOINTS: {
    mobile: 420,
    tablet: 768,
    desktop: 1024,
    wide: 1440,
    hd: 2560
  },
  // HTML title tag text (Useful to display the brand name for SEO)
  TITLE: "Anefi",
  // Recent category local storage token
  CATEGORY_TOKEN: "artisn-recent-category",
  // Local storage theme preference key
  THEME_PREFERENCE_TOKEN: "artisn-theme-preference",
  // Platform running the application
  PLATFORM: Capacitor.getPlatform() as ArtisnPlatform,
  // If true a registered user with address, billing data and orders will be present
  MOCK_WITH_INITIAL_USER_DATA: false,
  // Pay with place to pay
  PLACE_TO_PAY_ID: 4,
  // Anonymous shopping cart
  STORAGE: {
    ...SHARED_CONSTANTS.STORAGE,
    // Shipping address local storage token
    SHIPPING_ADDRESS_TOKEN: "artisn-selected-shipping-address",
    // Billing data local storage token
    BILLING_DATA_TOKEN: "artisn-selected-billing-data",
    // Card local storage token
    CARD_TOKEN: "artisn-selected-card",
    // Recent category local storage token
    CATEGORY_TOKEN: "artisn-recent-category",
    // Selected coordinates local storage token
    SELECTED_COORDINATES_TOKEN: "artisn-selected-coordinates",
    // Local storage theme preference key
    THEME_PREFERENCE_TOKEN: "artisn-theme-preference",
    // Anonymous shopping cart
    ANONYMOUS_SHOPPING_CART_TOKEN: "anonymous-shopping-cart",
    // Transfer anonymous id
    TRANSFER_ANONYMOUS_ID: "transfer-anonymous-id",
    // Pending order storage token
    PENDING_ORDER_TOKEN: "artisn-pending-order"
  },
  DATES_FORMAT: {
    HOUR_MINUTE: "HH:mm",
    COMPLETE_DATE_TIME: "YYYY-MM-DD HH:mm:ss"
  },
  FEATURE_FLAGS: {
    // Whether loyalty functionality should be enabled
    WITH_LOYALTY: false,
    // Whether coupons functionality should be enabled
    WITH_COUPONS: false,
    // Whether pickup functionality should be enabled
    WITH_PICK_UP,
    // Whether delivery functionality should be enabled
    WITH_DELIVERY,
    // Whether the app can sell
    WITH_PURCHASE,
    // Whether the user can select a purchase method
    WITH_MULTIPLE_PURCHASE_METHODS,
    // Whether wishlist functionality should be enabled
    WITH_WISHLIST: true,
    // Allow a product to be shared
    WITH_SHARE_PRODUCT: true,
    // Allow a shopping cart to be shared
    WITH_SHARE_SHOPPING_CART: true,
    // Allow anonymous shopping cart merge
    WITH_ANONYMOUS_SHOPPING_CART_TOKEN: true,
    // Allow anonymous flow
    WITH_ANONYMOUS: true,
    // Show the product details modal if needed
    // If not add product to cart without navigating to the details page
    // Show notification when product is added
    WITH_ADD_DIRECTLY_TO_CART: false,
    // Show product modal
    WITH_PRODUCT_MODAL: false,
    // Show cart drawer
    WITH_CART_DRAWER: false,
    // Show upsale modal
    WITH_UPSALE_MODAL: false,
    // Show crossale modal
    WITH_CROSSALE_MODAL: false,
    // Store coupons
    WITH_STORE_COUPONS: false,
    // Show talk shop
    WITH_TALK_SHOP: false,
    // Show SelectAddressDropdown component in Navbar
    WITH_SELECT_ADDRESS_DROPDOWN: false,
    // Allows the user to activate or deactivate the dark mode
    WITH_DARK_MODE: false,
    // Show AnonymousPasswordForm component in Checkout
    WITH_CHECKOUT_SIGNUP: false,
    // Activates the express checkout mode for payphone payments
    WITH_PAYPHONE_EXPRESS_CHECKOUT: false,
    // Enable logging with bugsnag
    WITH_BUGSNAG: false
  },
  INTEGRATIONS: {
    WHATSAPP: {
      PHONE_NUMBER: WHATSAPP_PHONE_NUMBER,
      RETURN_TO_APP_URL: `https://api.whatsapp.com/send/?phone=${WHATSAPP_PHONE_NUMBER}&text&app_absent=0`
    }
  },
  // Limit user actions
  CONSTRAINTS: {
    // Number of cards a user can have
    MAX_NUMBER_OF_CARDS: 3,
    // Number of shipping addresses a user can have
    MAX_NUMBER_OF_SHIPPING_ADDRESSES: 5,
    // Number of billing data a user can have
    MAX_NUMBER_OF_BILLING_DATA: 5
  },
  // App settings
  APP: {
    PLAY_STORE_URL:
      "https://play.google.com/store/apps/details?id=com.anefi&hl=en&gl=US",
    APPLE_STORE_URL: "https://apps.apple.com/ec/app/anefi/id1609277780",
    WEB_URL: ""
  },
  // DRIVE DOCUMENTS
  DOCUMENTS_URI: {
    TERMS_URI:
      "https://drive.google.com/file/d/18DbcAgpVRk84ushSpxAj0wCmhIp3UvUA/view?usp=sharing",
    POLITICS_URI:
      "https://drive.google.com/file/d/1yjPK5ubYlPaKeyugjoj-pz-HLrg_pdqM/view?usp=sharing"
  }
};
