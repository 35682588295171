import styled from "styled-components";

import { RadioStyledProps as Props } from "./Radio.types";

const RadioStyled = styled.div<Props>`
  display: flex;
  align-items: center;

  .Radio {
    &__input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      border: 0.1rem solid var(--palette-gray-s0-l70-a48);
      transition: 0.2s all linear;
      margin-right: 0.6rem;
      position: relative;

      &:checked {
        border: 0.6rem solid var(--palette-primary);
      }
    }

    &__label {
      font-size: 1.6rem;
      padding-left: 0.4rem;
      user-select: none;
    }
  }
`;

export default RadioStyled;
