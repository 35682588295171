import { CONSTANTS } from "@anefi/utils";
import { QueryClient } from "react-query";

const { DEFAULT_STALE_TIME } = CONSTANTS;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: process.env.ENV === "production",
      staleTime: DEFAULT_STALE_TIME
    }
  }
});
