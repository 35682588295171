import { BillingDataProvider, GeoProvider } from "@anefi/contexts";
import { CountriesProvider } from "@anefi/contexts";
import { CataloguesProvider } from "@anefi/contexts";
import { InvestFormProvider } from "@anefi/contexts";
import { PaymentsProvider } from "@anefi/contexts";
import { ProductsProvider } from "@anefi/contexts";
import { RiskProfileProvider } from "@anefi/contexts";
import { ShippingAddressProvider } from "@anefi/contexts";
import { ShoppingCartProvider } from "@anefi/contexts";
import { StoresProvider } from "@anefi/contexts";
import { VendorsProvider } from "@anefi/contexts";
import React from "react";

import { ArtisnProviderProps as Props } from "./ArtisnProvider.types";
import CheckoutProvider from "components/checkout/Checkout/context/checkout/checkout.context";
import AnalyticsProvider from "contexts/analytics/analytics.context";
import AuthProvider from "contexts/auth/auth.context";
import TalkShopProvider from "contexts/talkShop/talkShop/talkShop.context";

const ArtisnProvider: React.FC<Props> = props => {
  const { children } = props;

  return (
    <AnalyticsProvider>
      <AuthProvider>
        <CountriesProvider>
          <VendorsProvider>
            <PaymentsProvider>
              <BillingDataProvider>
                <StoresProvider>
                  <ProductsProvider>
                    <InvestFormProvider>
                      <RiskProfileProvider>
                        <GeoProvider>
                          <ShippingAddressProvider>
                            <ShoppingCartProvider>
                              <CataloguesProvider>
                                <CheckoutProvider>
                                  <TalkShopProvider>
                                    {children}
                                  </TalkShopProvider>
                                </CheckoutProvider>
                              </CataloguesProvider>
                            </ShoppingCartProvider>
                          </ShippingAddressProvider>
                        </GeoProvider>
                      </RiskProfileProvider>
                    </InvestFormProvider>
                  </ProductsProvider>
                </StoresProvider>
              </BillingDataProvider>
            </PaymentsProvider>
          </VendorsProvider>
        </CountriesProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
};

ArtisnProvider.defaultProps = {};

export default ArtisnProvider;
