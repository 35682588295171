// Setup Analytics library
import { useVendors } from "@anefi/contexts";
import { initAnalytics, checkInit, updateActiveVendor } from "artisn/analytics";
import { useEffect } from "react";

import { facebookProvider } from "config/artisn.config";
import { googleProvider } from "config/artisn.config";
import { CONSTANTS } from "config/constants";

import packageJSON from "../../../package.json";

const { ACCOUNT_ID, VENDORS, DEFAULT_VENDOR } = CONSTANTS;
const { id: vendorId } = DEFAULT_VENDOR;

const useSetupAnalytics = () => {
  const { selectedVendorId } = useVendors();

  useEffect(() => {
    if (typeof window === "undefined" || checkInit()) {
      return;
    }

    initAnalytics({
      activeVendor: vendorId,
      providers: [facebookProvider, googleProvider],
      vendors: VENDORS.map(vendor => vendor.id),
      meta: {
        accountId: ACCOUNT_ID,
        appName: packageJSON.name,
        versionApp: packageJSON.version
      },
      debug: false
    });
  }, []);

  useEffect(() => {
    if (typeof window === "undefined" || !checkInit()) {
      return;
    }

    updateActiveVendor(selectedVendorId);
  }, [selectedVendorId]);
};

export default useSetupAnalytics;
