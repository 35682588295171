import React from "react";

import Styles from "./InfoNotification.styles";
import { InfoNotificationProps as Props } from "./InfoNotification.types";
import Button from "components/global/Button/Button";
import { defaultFunction } from "utils/common.utils";

import InfoSVG from "../../../../../public/assets/images/avatar.svg";

const InfoNotification: React.FC<Props> = props => {
  const { title, description, buttonText, onButton } = props;
  const { opened = true, onClose = defaultFunction } = props;
  const { buttonTextMobile } = props;

  return (
    <Styles className="InfoNotification" opened={opened} onClose={onClose}>
      <div className="InfoNotification__icon">
        <InfoSVG viewBox="0 0 71 71" />
      </div>
      <p className="InfoNotification__title">{title}</p>
      <p className="InfoNotification__description">{description}</p>
      <Button
        type="BORDER"
        className="InfoNotification__button InfoNotification__button--desktop"
        onClick={onButton}
      >
        {buttonText}
      </Button>
      <Button
        type="BORDER"
        className="InfoNotification__button InfoNotification__button--mobile"
        onClick={onButton}
      >
        {buttonTextMobile}
      </Button>
    </Styles>
  );
};

InfoNotification.defaultProps = {};

export default InfoNotification;
