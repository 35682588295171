import { useBillingData, useGeo, useInvestForm } from "@anefi/contexts";
import { useCatalogues } from "@anefi/contexts";
import { usePayments } from "@anefi/contexts";
import { useProducts } from "@anefi/contexts";
import { useShoppingCart } from "@anefi/contexts";
import { useShippingAddress } from "@anefi/contexts";
import { useCountries } from "@anefi/contexts";
import { useVendors } from "@anefi/contexts";
import { useFetchUser } from "@anefi/services";
import Bugsnag from "@bugsnag/js";
import { Storage } from "@capacitor/storage";
import { events } from "artisn/analytics";

import { auth } from "config/artisn.config";
import { CONSTANTS } from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { createSuccessNotification } from "utils/notifications.utils";

const { STORAGE } = CONSTANTS;
const { BILLING_DATA_TOKEN } = STORAGE;
const { CATEGORY_TOKEN, SELECTED_COORDINATES_TOKEN, CARD_TOKEN } = STORAGE;
const { SHIPPING_ADDRESS_TOKEN, THEME_PREFERENCE_TOKEN } = STORAGE;
const { logSignOut } = events.auth;

const useSignOut = () => {
  const { resetBillingContext } = useBillingData();
  const { resetCataloguesContext } = useCatalogues();
  const { resetGeoContext } = useGeo();
  const { resetPaymentsContext } = usePayments();
  const { resetProductsContext } = useProducts();
  const { resetShoppingCartContext } = useShoppingCart();
  const { resetShippingAddressContext } = useShippingAddress();
  const { resetCountriesContext } = useCountries();
  const { resetVendorsContext } = useVendors();
  const authContext = useAuth();
  const { data: user } = useFetchUser(authContext);
  const { resetInvestFormContext } = useInvestForm();

  const { resetAuthContext } = authContext;

  const cleanLocalStorage = () => {
    const tokens = [
      SHIPPING_ADDRESS_TOKEN,
      BILLING_DATA_TOKEN,
      CATEGORY_TOKEN,
      THEME_PREFERENCE_TOKEN,
      SELECTED_COORDINATES_TOKEN,
      CARD_TOKEN
    ];
    tokens.forEach(token => {
      Storage.remove({
        key: token
      });
    });
  };

  const signOut = () => {
    try {
      cleanLocalStorage();
      resetBillingContext();
      resetCataloguesContext();
      resetCountriesContext();
      resetGeoContext();
      resetPaymentsContext();
      resetProductsContext();
      resetShoppingCartContext();
      resetShippingAddressContext();
      resetVendorsContext();
      resetInvestFormContext();
      auth().signOut();
      resetAuthContext();
      window.scrollTo(0, 0);
      logSignOut({ userId: user?.uid ?? "", name: user?.name ?? "" });
      createSuccessNotification(
        "Cerrar Sesión",
        "Has cerrado sesión con éxito!",
        4000
      );
    } catch (e) {
      Bugsnag.notify({
        ...e,
        name: "Sign out"
      });
      console.error(e.message);
    }
  };

  return signOut;
};

export default useSignOut;
