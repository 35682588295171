import { useBillingData } from "@anefi/contexts";
import { useFetchBillingData } from "@anefi/services";
import { Storage } from "@capacitor/storage";
import { useEffect } from "react";

import { CONSTANTS } from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";

const { BILLING_DATA_TOKEN } = CONSTANTS.STORAGE;

const useListenBillingData = () => {
  const { selectedBillingData, setSelectedBillingData } = useBillingData();
  const auth = useAuth();
  const { data: billingDataList } = useFetchBillingData(auth);
  const { isAnonymous } = auth;

  /** Get billing data from local storage and set it on context. */
  useEffect(() => {
    (async () => {
      const { value } = await Storage.get({
        key: BILLING_DATA_TOKEN
      });
      const lastSelectedBillingData = value ? JSON.parse(value) : undefined;

      setSelectedBillingData(lastSelectedBillingData);
    })();
  }, [setSelectedBillingData]);

  /**
   * Every time the selected billing data changes,
   * save it on local storage.
   */
  useEffect(() => {
    if (!selectedBillingData || isAnonymous) return;
    Storage.set({
      key: BILLING_DATA_TOKEN,
      value: JSON.stringify(selectedBillingData)
    });
  }, [isAnonymous, selectedBillingData]);

  useEffect(() => {
    if (!billingDataList) return;
    const defaultBillingData = billingDataList.find(
      billingData => billingData.default
    );
    if (!defaultBillingData) return;
    setSelectedBillingData(prev => {
      if (prev) return prev;
      return defaultBillingData;
    });
  }, [billingDataList, setSelectedBillingData]);
};

export default useListenBillingData;
