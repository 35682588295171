import { CONSTANTS } from "config/constants";

const { TITLE } = CONSTANTS;

const en = {
  title: "Anefi",
  home: {
    metaTitle: TITLE,
    metaDescription:
      "Anefi Web App, everything you need to know to invest in the best funds on the market in an easy, secure and transparent way."
  },
  productsSearch: {
    metaTitle: "Discover"
  },
  loyalty: {
    metaTitle: "Loyalty"
  },
  coupons: {
    metaTitle: "Coupons",
    detailTitle: "Coupons"
  },
  profile: {
    metaTitle: "User"
  },
  navigation: {
    home: "Inicio",
    invest: "Invierte",
    balance: "Balance",
    profile: "Perfil",
    shopping: "Compras",
    discover: "Descubre",
    coupons: "Cupones"
  },
  catalogues: {
    delivery: "Delivery",
    pickup: "Pick-Up",
    other: "Other"
  },
  common: {
    preferences: "Preferences",
    workflows: "Purchase method",
    apply: "Apply",
    buyingIn: "Buying in",
    catalogue: "Catalogue"
  },
  commonFieldForm: {
    names: "Nombres",
    lastnames: "Apellidos",
    name: "Nombre",
    secondName: "Segundo nombre",
    lastname: "Apellido",
    secondLastname: "Segundo apellido",
    fullName: "Nombre completo",
    documentType: "Tipo de documento",
    document: "Cédula",
    documentLabel: "Número de documento",
    passport: "Pasaporte",
    address: "Dirección",
    addressType: "Tipo de vivienda",
    birthDate: "Fecha de nacimiento",
    email: "Correo electrónico",
    ruc: "RUC",
    phone: "Celular",
    male: "Masculino",
    female: "Femenino",
    fingerprint: "Código dactilar"
  },
  stepOneForm: {
    nationality: "Nacionalidad",
    nationalityModal: "Seleccione una nacionalidad",
    residence: "Residencia",
    residenceModal: "Seleccione su país de residencia",
    expose: "¿Se considera una persona políticamente expuesta (PEP)?",
    emailPlaceholder: "hola@correo.com",
    genre: "¿Con cuál género te identificas?",
    phoneType: "Tipo de teléfono",
    minAge: "La fecha de nacimiento no puede ser menor a 18 años"
  },
  stepTwoForm: {
    country: "Selecciona el país"
  },
  stepThreeForm: {
    maritalStatus: "Estado Civil",
    dependentType: "Dependencia"
  },
  stepFourForm: {
    province: "Provincia",
    canton: "Cantón",
    IshomeAddressFiscal: "Es una dirección fiscal"
  },
  stepFiveForm: {
    independent: "Independiente",
    employee: "Empleado"
  },
  independentForm: {
    businessType: "Tipo de negocio",
    businessName: "Nombre del negocio",
    activityTime: "Tiempo de la actividad",
    businessAddress: "Dirección",
    businessPhone: "Teléfono"
  },
  employeeForm: {
    employeeInstitution: "Nombre de la institución",
    employeeArea: "Área / Departamento",
    employeePosition: "Cargo",
    employeeSeniority: "Antigüedad",
    employeeAddress: "Dirección",
    employeePhone: "Teléfono"
  },
  stepSixForm: {
    principalIncome: "Ingresos de la actividad económica principal",
    otherIncome: "Otros ingresos",
    detailIncome: "Detalle de otros ingresos",
    totalExpenses: "Total de egresos",
    detailExpenses: "Detalle de egresos"
  },
  stepSevenForm: {
    title: "Detalla tu situación patrimonial",
    totalAssets: "Total activos",
    totalLiabilities: "Total pasivos",
    totalEquity: "Total patrimonio (Activo - pasivo)",
    businessActivityTypeCode: "¿Cuál es tu actividad económica?",
    businessActivityTypeCodeModal: "Actividad económica",
    totalIncome: "Total ingresos",
    monthlyExpenses: "Gastos mensuales"
  },
  stepEightForm: {
    formLabel: "Adjunta las siguientes fotos...",
    basicService: "Foto de planilla de servicios básicos",
    ciFront: "Foto frontal de tu cédula",
    ciBack: "Foto posterior de tu cédula"
  },
  stepNineForm: {
    bankEntity: "Selecciona la entidad bancaria",
    bankEntityModal: "Entidad bancaria",
    bankAccountType: "Tipo de cuenta"
  },
  legalStepOneForm: {
    businessName: "Nombre del negocio",
    startDate: "Fecha de constitución"
  },
  legalStepTwoForm: {
    workCountry: "Pais de la dirección de trabajo",
    principalStreet: "Calle principal",
    numberWorkAddress: "Numeración",
    intersectionWorkAddress: "Intersección",
    phone: "Teléfono"
  },
  legalStepThreeForm: {
    totalAssets: "Total activos",
    totalLiabilities: "Total pasivos",
    totalIncome: "Total ingresos",
    totalExpenditure: "Total gastos"
  },
  bankAccountForm: {
    title: "Nueva cuenta",
    financialInstitutionPlaceholder: "Entidad bancaria",
    financialInstitutionTitle: "Entidades bancarias",
    accountNumber: "Número de cuenta",
    nameAndLastName: "Nombres y apellido"
  },
  updatePassword: {
    successTitle: "Cambio exitoso",
    successMessage: "Contraseña actualizada correctamente",
    verifyPassword: "Verifica que tu contraseña actual sea correcta",
    error: "Ocurrió un error, No fue posible actualizar la contraseña",
    newPassword: "Crea tu nueva contraseña"
  },
  checkout: {
    errorPaymentMethod: "Seleccione un método de pago para continuar",
    selectProduct: "Elija un producto"
  },
  error: {
    products: {
      title: "¡Oops! Hubo un problema al cargar los productos",
      subTitle:
        "Al parecer algo falló al cargar los productos, intenta nuevamente. "
    }
  },
  invest: {
    disclaimer: "Tu aporte se verá reflejado en 24 horas hábiles",
    contribution: "Aporte",
    contribute: "Aportar",
    invest: "Invertir",
    andContinue: "y continuar",
    balanceToInvest: "Saldo Efectivo para invertir",
    noBalance: "No tienes saldo suficiente. Realiza un",
    continueInvesting:
      "a tu Saldo Efectivo para poder continuar con tu inversión",
    selectProduct: "Elige el fondo para realizar el rescate",
    subTitleInvest: "Con tu saldo podrás invertir en fondos y mucho más",
    howMuch: "Cuánto quieres",
    availableBalance: "Saldo Disponible Efectivo"
  },
  productMovementsDate: {
    title: "Rendimiento",
    unitValue: "Val. Unidad ",
    amount: "Monto",
    performance: "Haz generado en rendimiento: ",
    balance: "Saldo",
    emptyTitle: "No se encontraron balances",
    emptyBtn: "Explora productos",
    errorTitle: "¡Oops! Hubo un problema al cargar los balances",
    errorSubTittle:
      "Al parecer algo falló al cargar los balances, intenta recargando la página"
  },
  linkedDocument: {
    title1: "La cédula",
    title2: "está asociada a otro correo",
    updateAccount: "Actualiza tu cuenta para utilizar este correo \n",
    updateMyAccount: "Actualizar a mi nuevo correo",
    signOutInfo:
      "Puedes cerrar la sesión y volver a ingresar usando tu antiguo correo \n",
    signOut: "Cerrar sesión e ingresar otra vez"
  },
  migrateAccount: {
    title1: "Para actualizar tu correo a",
    title2: "debes tener en cuenta lo siguiente:",
    agree: "Estoy de acuerdo con actualizar mi correo",
    migrate: "Actualizar a mi nuevo correo",
    indication1:
      "Los estados de cuenta de tus inversiones se enviarán a tu nuevo correo electrónico.",
    indication2:
      "Para confirmar tu identidad enviaremos un código de seguridad a tu antiguo correo registrado.",
    indication3:
      "Tendrás acceso a la información de tus inversiones solo con tu nuevo correo.",
    updateMyAccount: "Actualizar a mi nuevo correo"
  },
  signUpPassword: {
    title: "Vamos a crear tu usuario",
    emailLabel: "Registra tu correo electrónico",
    emailPlaceholder: "hola@correo.com",
    passwordLabel: "Crea tu contraseña",
    passwordPlaceholder: "********",
    submitButton: "Continuar",
    errorCreate: "No se pudo crear el usuario"
  },
  contactUS: {
    homeNumber: "Número fijo",
    email: "Correo",
    call: "Llamar",
    goToWP: "Ir a Whatsapp",
    title: "Soporte",
    subtitle: "Contactar con soporte",
    titleOTP: "Hemos enviado un correo automático a soporte",
    subTitleOTP: "Nos pondremos en contacto a la brevedad posible al número",
    subTitleBottom:
      "Si necesitas ayuda en este momento puedes contactarte con nuestros canales"
  },
  signUpUser: {
    accept: "Estoy de acuerdo con los ",
    terms: "Términos y condiciones",
    and: " y ",
    privacy: "Políticas de Privacidad.",
    acceptEmail: "Acepto recibir correos electrónicos",
    acceptPush: "Acepto recibir notificaciones push",
    requiredTerms: "Debes aceptar los términos y condiciones"
  },
  privacyModal: {
    title: "Te invitamos a revisar las nuevas políticas y aceptarlas.",
    paragraph1:
      "Manejamos la información que nos brindas para gestionar nuestras     iniciativas comerciales y mantener una relación cercana con nuestros     clientes.",
    paragraph2:
      "Queremos que sepas que tienes el derecho de acceder, actualizar, revocar, eliminar y oponerte al tratamiento de tus datos. Si deseas ejercer alguno de estos derechos, no dudes en enviarnos un correo electrónico a: serviciocliente@anefi.com.ec. Estaremos encantados de ayudarte en todo lo que necesites.",
    accept: "Aceptar"
  },
  deleteAccount: {
    errorDeleted: "No se pudo eliminar la cuenta",
    title: "Eliminar cuenta",
    question: "¿Seguro deseas eliminar tu cuenta?",
    description:
      "Una vez que elimines tu cuenta no podrás volver a recuperarla y deberás crear una cuenta nueva.",
    cancel: "Mantener mi cuenta",
    delete: "Eliminar mis datos"
  }
};

export default en;
