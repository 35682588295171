import { useStores } from "@anefi/contexts";
import { useCatalogues } from "@anefi/contexts";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { updateShoppingCart, checkInit } from "artisn/shopping-cart";
import { CartProduct, ShoppingCart } from "artisn/types";
import { useEffect } from "react";

import { CONSTANTS } from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useShippingCost from "hooks/useShippingCost";

const { SHOPPING_CART_DEFAULT_NAME, CATALOGUES } = CONSTANTS;
const [DELIVERY] = CATALOGUES;

const useListenCatalogue = () => {
  const { isAnonymous } = useAuth();
  const { selectedCatalogueId } = useCatalogues();
  const { selectedStore } = useStores();
  const isDelivery = selectedCatalogueId === DELIVERY.catalogueId;
  const shippingCost = useShippingCost();

  useEffect(() => {
    if (!selectedStore || !checkInit()) return;
    updateShoppingCart(
      prevCart => {
        delete prevCart.pickUpTime;
        const { storeId } = selectedStore;
        const products = getShoppingCartProducts(prevCart);
        const newShoppingCart: ShoppingCart = {
          ...prevCart,
          channelId: +selectedCatalogueId,
          shippingCost: isDelivery ? shippingCost : null
        };
        if (!products.length) return newShoppingCart;
        const newProducts: Record<string, CartProduct> = {};
        products.forEach(product => {
          const { hash } = product;
          newProducts[hash] = product;
        });

        newShoppingCart.stores = {
          [storeId]: { ...selectedStore, products: newProducts, alerts: [] }
        };

        return newShoppingCart;
      },
      {
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous
      }
    );
  }, [
    isAnonymous,
    selectedCatalogueId,
    selectedStore,
    isDelivery,
    shippingCost
  ]);
};

export default useListenCatalogue;
