import { useFetchUser, usePostValidateRegisterPerson } from "@anefi/services";
import { AnefiDocumentType } from "@anefi/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { Clickable, TextInput } from "artisn-ui-react";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";

import useModals from "contexts/modals/modals.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import { getMaxLength } from "utils/form.utils";
import Button from "../Button/Button";
import { defaultValuesForm, userVerifyForm } from "./UserVerifyModal.helpers";
import { mapDocumentAnefiTypeToArtisnType } from "./UserVerifyModal.helpers";
import { inputDocumentTypeCodes } from "./UserVerifyModal.helpers";
import { CONSTANTS } from "config/constants";
import Radio from "../form/Radio/Radio";
import { ValueRadio } from "components/global/form/Radio/Radio.types";
import Styles from "./UserVerifyModal.styles";
import { UserVerifyFormValues } from "./UserVerifyModal.types";
import { UserVerifyModalProps as Props } from "./UserVerifyModal.types";
import useSignOut from "hooks/useSignOut";
import { notify, sanitizeQueryParams } from "utils/common.utils";

import CloseSVG from "../../../../public/assets/images/anefi/close-modal.svg";

const { REGISTER_USER_RESPONSE, USER_EXISTS_ERROR } = CONSTANTS;
const { USER_EXISTS_WITH_EMAIL_ERROR } = CONSTANTS;

const UserVerifyModal: React.FC<Props> = props => {
  const { replace, back, query } = useRouter();
  const { redirect } = sanitizeQueryParams(query);
  const queryClient = useQueryClient();
  const auth = useAuth();
  const { data: user } = useFetchUser(auth);
  const { mutate: validateRegister, isLoading } =
    usePostValidateRegisterPerson(auth);
  const signOut = useSignOut();
  const { userVerifyFormVisible, setUserVerifyFormVisible } = useModals();
  const [documentCode, setDocumentCode] = useState<AnefiDocumentType>();
  const [error, setError] = useState("");

  const { documentType } = user ?? {};
  const { setSignInUser, isAnonymous, setSignInUserMigrate } = auth;

  const formMethods = useForm<UserVerifyFormValues>({
    mode: "onChange",
    resolver: yupResolver(userVerifyForm(documentCode)),
    defaultValues: defaultValuesForm
  });
  const { register, control, formState } = formMethods;
  const { handleSubmit, reset, setValue, clearErrors } = formMethods;

  const documentTypeCodeWatch = useWatch({ control, name: "documentTypeCode" });
  const { document, documentTypeCode } = formState.errors ?? {};

  const signOutHandler = () => {
    reset({
      documentTypeCode: undefined,
      document: undefined
    });
    setError("");
    signOut();
    setUserVerifyFormVisible(false);
    back();
  };

  const goToSupport = () => {
    reset({
      documentTypeCode: undefined,
      document: undefined
    });
    setError("");
    signOut();
    setUserVerifyFormVisible(false);
    replace("/profile/support/contact-us");
  };

  const submitHandler = async (form: UserVerifyFormValues) => {
    setError("");
    const { document, documentTypeCode } = form;
    const { uid, email } = auth;

    validateRegister(
      {
        payload: { ...form, status: "ACT", productTypeCode: "F" },
        uid,
        email
      },
      {
        onSuccess: async data => {
          setSignInUser(prev => ({
            ...prev,
            document,
            documentType: mapDocumentAnefiTypeToArtisnType(documentTypeCode)
          }));

          const { dependenceType } = data;

          if (dependenceType === REGISTER_USER_RESPONSE) {
            reset(defaultValuesForm);
            setUserVerifyFormVisible(false);
            replace({
              pathname: "/signup-user",
              query: {
                redirect
              }
            });
            return;
          }

          notify(data, "UserVerify success dependency registered");
          setError("userExistsWithEmail");
        },
        onError: async e => {
          const errorValidation = JSON.parse(e.message);
          const errorMessage = errorValidation.message;
          if (
            errorMessage !== USER_EXISTS_WITH_EMAIL_ERROR &&
            errorMessage !== USER_EXISTS_ERROR
          ) {
            notify(error, "UserVerify");
            setError("generic");
            return;
          }

          const errorData = errorValidation.data;
          const { email, personValidationLogId } = errorData ?? {};
          if (!email || !personValidationLogId) {
            setError("userExistsWithEmail");
            notify(error, "UserVerify no data to migrate");
            return;
          }
          setSignInUserMigrate(prev => ({
            ...prev,
            personValidationLogId,
            email,
            document: form.document,
            documentType: mapDocumentAnefiTypeToArtisnType(
              form.documentTypeCode
            )
          }));
          reset();
          setUserVerifyFormVisible(false);
          await queryClient.refetchQueries([uid, "user"]);
          return;
        }
      }
    );
  };

  const handleRadioClick = (value: ValueRadio) => {
    setValue("documentTypeCode", value as AnefiDocumentType);
    clearErrors("documentTypeCode");
  };

  useEffect(() => {
    setDocumentCode(documentTypeCodeWatch);
  }, [documentTypeCodeWatch]);

  if (isAnonymous || documentType) return null;

  const supportButtonNode = (
    <Button
      className="UserVerifyModal__error"
      type="LINK"
      onClick={goToSupport}
    >
      Soporte
    </Button>
  );

  const renderGenericError = () => (
    <div className="UserVerifyModal__div-error">
      <p className="UserVerifyModal__error">
        Ocurrió un error inesperado con el documento ingresado, contáctate con
      </p>
      {supportButtonNode}
    </div>
  );

  const renderSupportError = () => (
    <div className="UserVerifyModal__div-error">
      <p className="UserVerifyModal__error">
        El número de documento con el usuario registrado no coinciden. Por favor
        inicie sesión con el usuario registrado.
      </p>
      <Button
        type="LINK"
        className="UserVerifyModal__error"
        onClick={signOutHandler}
      >
        Cierre sesión
      </Button>
      <p className="UserVerifyModal__error"> o consulte con </p>
      {supportButtonNode}
    </div>
  );

  const handleFocus = () => {
    setError("");
  };

  return (
    <Styles
      className="UserVerifyModal"
      closeOnClickOutside={false}
      opened={userVerifyFormVisible}
      onClose={() => {
        reset(defaultValuesForm);
      }}
    >
      <Clickable onClick={signOutHandler} className="UserVerifyModal__close">
        <CloseSVG />
      </Clickable>
      <h2 className="UserVerifyModal__title">Confirmación de datos</h2>
      <p className="UserVerifyModal__subtitle">
        Ingresa tu número de RUC o cédula para proceder con una validación de
        datos.
      </p>

      <form
        className="UserVerifyModal__form"
        onSubmit={handleSubmit(submitHandler)}
      >
        <fieldset className="UserVerifyModal__form__document">
          <legend className="UserVerifyModal__form__documentTitle">
            Tipo de documento
          </legend>

          <div className="UserVerifyModal__form__radio">
            {inputDocumentTypeCodes.map(inputDocumentType => (
              <div key={inputDocumentType.value}>
                <Radio
                  onClick={handleRadioClick}
                  name="documentTypeCode"
                  value={inputDocumentType.value}
                  label={inputDocumentType.label}
                  disabled={isLoading}
                  isSelected={inputDocumentType.value === documentCode}
                />
              </div>
            ))}
          </div>
          <p className="UserVerifyModal__error">{documentTypeCode?.message}</p>
        </fieldset>

        <TextInput
          {...register("document")}
          label="Número de documento"
          className="UserVerifyModal__documentInput field"
          errorMessage={document?.message}
          maxLength={getMaxLength(documentTypeCodeWatch)}
          disabled={isLoading}
          onFocus={handleFocus}
        />

        {error === "generic" ? renderGenericError() : null}

        {error === "userExistsWithEmail" ? renderSupportError() : null}

        <Button
          type="FILLED"
          color="primary"
          htmlType="submit"
          className="SignUpUserForm__button"
          isLoading={isLoading}
          disabled={isLoading}
        >
          Confirmar datos
        </Button>
      </form>
    </Styles>
  );
};

UserVerifyModal.defaultProps = {};

export default UserVerifyModal;
