import { useCountries } from "@anefi/contexts";
import { ProductCard as ProductCardUI } from "artisn-ui-react";
import { useProductCard } from "artisn-ui-react";
import { formatByCurrency } from "artisn-ui-react";
import React from "react";

import Styles from "./HorizontalProductCard.styles";
import { HorizontalProductCardProps as Props } from "./HorizontalProductCard.types";
import ProductBasePriceInfo from "components/global/ProductBasePriceInfo/ProductBasePriceInfo";
import ProductCardPlaceholder from "components/global/ProductCard/ProductCard.placeholder";
import useDefaultCurrencyFormatter from "hooks/useDefaultCurrencyFormatter";

const { Description, Image, Name } = ProductCardUI;

const HorizontalProductCard: React.FC<Props> = props => {
  const { product, className, onClick, carousel = false, modifiers } = props;
  const enhancedProduct = carousel
    ? { ...product, images: [product.images[0]] }
    : product;
  const { decimals } = useCountries().selectedCountry;
  const { totals } = useProductCard(product, { decimals });
  const { netPrice } = totals ?? {};
  const defaultFormatter = useDefaultCurrencyFormatter();

  return (
    <Styles className={`HorizontalProductCard ${className}`} onClick={onClick}>
      <ProductCardUI
        className="HorizontalProductCard__product-details"
        product={enhancedProduct}
        placeholder={<ProductCardPlaceholder />}
      >
        <Image
          className="HorizontalProductCard__image"
          width={100}
          height={100}
          alt={enhancedProduct.name}
        />
        <Name className="HorizontalProductCard__name" />
        {modifiers ? (
          <div className="HorizontalProductCard__description">{modifiers}</div>
        ) : (
          <Description className="HorizontalProductCard__description" />
        )}
        <ProductBasePriceInfo
          className="HorizontalProductCard__price"
          productTotals={totals}
          showUnits
        />

        <div className="HorizontalProductCard__total">
          {formatByCurrency(netPrice ?? 0, defaultFormatter)}
        </div>
      </ProductCardUI>
    </Styles>
  );
};

HorizontalProductCard.defaultProps = {
  className: ""
};

export default HorizontalProductCard;
