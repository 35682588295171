import useListenAddresses from "./listeners/useListenAddresses";
import useListenBillingData from "./listeners/useListenBillingData";
import useListenCatalogue from "./listeners/useListenCatalogue";
import useListenGeo from "./listeners/useListenGeo";
import useListenGlobals from "./listeners/useListenGlobals";
import useListenPayments from "./listeners/useListenPayments";
import useListenShoppingCart from "./listeners/useListenShoppingCart";
import useListenStores from "./listeners/useListenStores";
import useListenUser from "./listeners/useListenUser";
import useListenVendor from "./listeners/useListenVendor";

const useListeners = () => {
  useListenUser();
  useListenGeo();
  useListenStores();
  useListenVendor();
  useListenAddresses();
  useListenBillingData();
  useListenShoppingCart();
  useListenPayments();
  useListenCatalogue();
  useListenGlobals();
};

export default useListeners;
