import { useShoppingCart } from "@anefi/contexts";
import { Image } from "artisn-ui-react";
import { setProduct } from "artisn/shopping-cart";
import React from "react";

import { defaultImageConfig } from "../AddToWishlistNotification/AddToWishlistNotification.helpers";
import { AddToCartNotificationStyled } from "./AddToCartNotification.styles";
import { AddToCartNotificationModifiable } from "./AddToCartNotification.styles";
import { AddToCartNotificationProps as Props } from "./AddToCartNotification.types";
import CartPayButton from "components/Cart/CartPayButton/CartPayButton";
import ShoppingCartProduct from "components/Cart/ShoppingCartProduct/ShoppingCartProduct";
import Button from "components/global/Button/Button";
import useAuth from "contexts/auth/auth.context.hooks";
import { defaultFunction } from "utils/common.utils";
import { dismissAddToCartNotification } from "utils/notifications.utils";

const AddToCartNotification: React.FC<Props> = props => {
  const { className, product, showDrawer = defaultFunction } = props;
  const { amount = 1, modifiable } = props;
  const { isAnonymous } = useAuth();
  const { shoppingCart } = useShoppingCart();
  const { images, name } = product ?? {};
  const { name: shoppingCartName } = shoppingCart ?? {};

  if (modifiable) {
    return (
      <AddToCartNotificationModifiable
        className={`AddToCartNotification ${className}`}
        showCloseIcon={true}
        onClose={dismissAddToCartNotification}
      >
        <p className="AddToCartNotification__description">Haz agregado</p>
        {product ? (
          <ShoppingCartProduct
            product={product}
            onChangeQuantity={(value: number) =>
              setProduct(product, {
                amount: value,
                shoppingCartName,
                anonymous: isAnonymous
              })
            }
            initialQuantity={amount}
          />
        ) : null}
        <div className="AddToCartNotification__buttons">
          <Button
            className="AddToCartNotification__button"
            type="FILLED"
            onClick={showDrawer}
          >
            Ver carrito
          </Button>
          <CartPayButton shoppingCart={shoppingCart} />
        </div>
      </AddToCartNotificationModifiable>
    );
  }

  return (
    <AddToCartNotificationStyled
      className={`AddToCartNotification ${className}`}
      showCloseIcon={false}
    >
      {images?.[0] ? (
        <Image
          image={images?.[0].url}
          config={defaultImageConfig}
          alt="Action icon"
          className="AddToCartNotification__image"
        />
      ) : null}
      <p className="AddToCartNotification__description">Haz agregado: {name}</p>
      <Button
        className="AddToCartNotification__button"
        type="LINK"
        onClick={showDrawer}
      >
        Ver carrito
      </Button>
    </AddToCartNotificationStyled>
  );
};

AddToCartNotification.defaultProps = {};

export default AddToCartNotification;
