import { useCountries } from "@anefi/contexts";
import { useFetchUser, usePutUser, usePutUserSettings } from "@anefi/services";
import { events } from "artisn/analytics";
import React, { useState } from "react";

import Styles from "./PrivacyModal.styles";
import { PrivacyModalProps as Props } from "./PrivacyModal.types";
import Button from "components/global/Button/Button";
import Checkbox from "components/global/Checkbox/Checkbox";
import { CONSTANTS } from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useModals from "contexts/modals/modals.hooks";
import useI18n from "hooks/useI18n";
import { notify } from "utils/common.utils";
import { changeTimezone } from "utils/date.utils";

const { TERMS_URI, POLITICS_URI } = CONSTANTS.DOCUMENTS_URI;
const { logUpdateUserInfo } = events.user;

const PrivacyModal: React.FC<Props> = props => {
  const auth = useAuth();
  const fetchUserQuery = useFetchUser(auth);
  const putUserQuery = usePutUser(auth);
  const putUserSettingsQuery = usePutUserSettings(auth);
  const { selectedCountry } = useCountries();
  const { privacyModalVisible, setPrivacyModalVisible } = useModals();
  const t = useI18n();
  const [privacy, setPrivacy] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { data: user, isSuccess } = fetchUserQuery;
  const { mutate: updateUser, reset: resetPutUser } = putUserQuery;
  const { reset: resetUserSettings } = putUserSettingsQuery;
  const { mutateAsync: updateUserSettings } = putUserSettingsQuery;
  const { uid } = auth;

  const handleClick = () => {
    setError("");
    setIsLoading(true);

    try {
      if (!uid) {
        throw new Error("No se pudo obtener el id del usuario");
      }

      if (!user) {
        throw new Error("No se pudo obtener el usuario");
      }

      if (!privacy) {
        throw new Error(t.signUpUser.requiredTerms);
      }
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
      return;
    }

    resetPutUser();
    resetUserSettings();

    const updatedUser = {
      ...user,
      privacyAcceptDate: changeTimezone(new Date()).toISOString(),
      uid,
      active: !!user.active,
      country: { id: selectedCountry.id }
    };

    updateUser(updatedUser, {
      onSuccess: async () => {
        logUpdateUserInfo({
          userId: uid,
          fields: ["terms"]
        });

        try {
          await updateUserSettings({
            settings: {
              emailNotifications,
              pushNotifications: emailNotifications
            }
          });
          setIsLoading(false);
          setPrivacyModalVisible(false);
        } catch (e) {
          setIsLoading(false);
          notify(e, "Privacy Modal update user");
          setError("No se pudo actualizar las preferencias de notificación");
        }
      },
      onError: e => {
        setError("No se pudo actualizar el usuario");
        notify(e, "Privacy Modal update user");
        setIsLoading(false);
      }
    });
  };

  return (
    <Styles
      className="PrivacyModal"
      opened={privacyModalVisible}
      closeOnClickOutside={false}
    >
      <h3 className="PrivacyModal__title">{t.privacyModal.title}</h3>

      <p className="PrivacyModal__paragraph">{t.privacyModal.paragraph1}</p>
      <p className="PrivacyModal__paragraph">{t.privacyModal.paragraph2}</p>

      <Checkbox
        className="PrivacyModal__checkbox"
        label={
          <p className="PrivacyModal__terms">
            {t.signUpUser.accept}
            <a href={TERMS_URI} target="_blank" rel="noreferrer">
              {t.signUpUser.terms}
            </a>
            {t.signUpUser.and}
            <a href={POLITICS_URI} target="_blank" rel="noreferrer">
              {t.signUpUser.privacy}
            </a>
          </p>
        }
        name="privacy"
        defaultChecked={privacy}
        onChange={() => setPrivacy(prev => !prev)}
      />

      <Checkbox
        className="PrivacyModal__checkbox"
        label={
          <p className="PrivacyModal__terms">{t.signUpUser.acceptEmail}</p>
        }
        name="emailNotifications"
        defaultChecked={emailNotifications}
        onChange={() => setEmailNotifications(prev => !prev)}
      />

      <p className="PrivacyModal__error-message">{error}</p>
      <Button
        className="PrivacyModal__button"
        onClick={handleClick}
        disabled={isLoading || !isSuccess || !privacy}
      >
        {t.privacyModal.accept}
      </Button>
    </Styles>
  );
};

PrivacyModal.defaultProps = {};

export default PrivacyModal;
