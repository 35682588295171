// useShippingCost custom hook

import { useCatalogues, useGeo } from "@anefi/contexts";
import { useStores } from "@anefi/contexts";
import { useFetchShippingCost } from "@anefi/services";
import { useMemo } from "react";

const useShippingCost = () => {
  const { catalogueId } = useCatalogues().selectedCatalogue;
  const { selectedStore } = useStores();
  const { storeId } = selectedStore ?? {};
  const { selectedCoordinates } = useGeo();
  const { lat, lng } = selectedCoordinates ?? {};

  const { data: shippingCost } = useFetchShippingCost({
    storeId,
    catalogueId,
    lat,
    lng
  });

  return useMemo(() => {
    return shippingCost ?? null;
  }, [shippingCost]);
};

export default useShippingCost;
