import { useInvestForm } from "@anefi/contexts";
import { useFetchUser } from "@anefi/services";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { transformUserToLegal } from "./useListenUser.helper";
import { transformUserToNatural } from "./useListenUser.helper";
import { CompleteUser } from "./useListenUser.helper";
import useAuth from "contexts/auth/auth.context.hooks";
import useModals from "contexts/modals/modals.hooks";

const useListenUser = () => {
  const router = useRouter();
  const auth = useAuth();
  const fetchUserQuery = useFetchUser(auth);
  const { setInvestForm, setLegalInvestForm } = useInvestForm();
  const modalsMethods = useModals();

  const { data: user, error, status } = fetchUserQuery;
  const { isSuccess: isSuccessUser } = fetchUserQuery;
  const { isSignedIn, signInUser, isAnonymous } = auth;
  const { signInUserMigrate } = auth;
  const { setUserVerifyFormVisible, userVerifyFormVisible } = modalsMethods;
  const { setPrivacyModalVisible } = modalsMethods;
  const { message } = error ?? {};
  const { privacyAcceptDate } = user ?? {};
  const showPrivacy =
    !isAnonymous && isSuccessUser && user && !privacyAcceptDate;

  useEffect(() => {
    if (!user?.additionalInfo) return;
    if (user?.additionalInfo?.personType === "LEGAL") {
      setLegalInvestForm(transformUserToLegal(user as CompleteUser));
    } else {
      setInvestForm(transformUserToNatural(user as CompleteUser));
    }
  }, [setInvestForm, setLegalInvestForm, user]);

  useEffect(() => {
    if (status !== "error") return;

    if (!router.isReady) return;

    const currentRouteName = router.pathname;

    if (!isSignedIn) return;

    if (isSignedIn && user) return;

    if (currentRouteName === "/linked-document") return;
    if (currentRouteName === "/migrate-account") return;
    if (currentRouteName === "/signup-user") return;
    if (currentRouteName === "/login-social-media") return;
    if (currentRouteName === "/signup-password") return;

    if (message !== "No user for uid") return;

    if (signInUserMigrate) {
      router.push("/linked-document");
      return;
    }

    if (signInUser) return;

    if (userVerifyFormVisible) return;
    setUserVerifyFormVisible(true);
  }, [
    isSignedIn,
    message,
    router,
    setUserVerifyFormVisible,
    signInUser,
    signInUserMigrate,
    status,
    user,
    userVerifyFormVisible
  ]);

  useEffect(() => {
    if (!showPrivacy) return;

    const timer = setTimeout(() => {
      setPrivacyModalVisible(true);
    }, 3 * 1000);

    return () => {
      clearTimeout(timer);
      setPrivacyModalVisible(false);
    };
  }, [setPrivacyModalVisible, showPrivacy]);
};

export default useListenUser;
