import { useGeo, useStores } from "@anefi/contexts";
import { validateShoppingCart } from "artisn/shopping-cart";
import { getShoppingCartTotal } from "artisn/shopping-cart";
import React, { useMemo, useState } from "react";

import Styles from "./CartPayButton.styles";
import { CartPayButtonProps as Props } from "./CartPayButton.types";
import Button from "components/global/Button/Button";
import { CONSTANTS } from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { usePostTalkShop } from "services/talkShop/talkShop/talkShop.service.hooks";
import { defaultFunction, shouldMock } from "utils/common.utils";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";

const { SHOPPING_CART_DEFAULT_NAME } = CONSTANTS;
const { WITH_PURCHASE, WITH_DELIVERY } = CONSTANTS.FEATURE_FLAGS;

const CartPayButton: React.FC<Props> = props => {
  const { empty, shoppingCart, setDisabled = defaultFunction } = props;
  const { setErrors = defaultFunction, className, talkShop } = props;
  const { selectedCoordinates } = useGeo();
  const { uid, isAnonymous } = useAuth();
  const [, setShowStoreNotification] = useState(false);
  const [, setShowNoCoverage] = useState(false);
  const [, setShowMapCoordinates] = useState(false);
  const { isLoading } = usePostTalkShop();
  const { selectedStore } = useStores();
  const shoppingCartTotal = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartTotal(shoppingCart);
  }, [shoppingCart]);
  const { minOrderAmount = 0, maxOrderAmount = 0 } = selectedStore ?? {};
  const { total = 0 } = shoppingCartTotal ?? {};

  const payButtonHandler = async () => {
    if (minOrderAmount > 0 && minOrderAmount > total) {
      dismissErrorNotification();
      createErrorNotification(
        `Agrega al menos $${minOrderAmount} para realizar tu pedido`,
        "Tu pedido no cumple con el monto mínimo"
      );
      return;
    }

    if (maxOrderAmount > 0 && maxOrderAmount < total) {
      dismissErrorNotification();
      createErrorNotification(
        `Agrega artículos hasta un valor de $${maxOrderAmount} para poder hacer tu pedido`,
        "Tu pedido excede el monto máximo"
      );
      return;
    }

    if (!selectedCoordinates) {
      setShowMapCoordinates(true);
      return;
    }
    if (!shoppingCart || !uid) return;
    if (!Object.keys(shoppingCart.stores).length) return;
    const { lat, lng } = selectedCoordinates;
    const validate = !shouldMock ? validateShoppingCart : () => undefined;

    const errors = await validate({
      latitude: lat,
      longitude: lng,
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous
    });
    const { products, stores } = errors ?? {};
    stores?.forEach(store => {
      const { type } = store;
      if (type === "IS_OPEN" && stores.length === 1) {
        setShowStoreNotification(true);
      }
      if (type === "OUT_OF_COVERAGE" && WITH_DELIVERY) {
        setShowNoCoverage(true);
      }
    });
    const validationErrors = stores?.filter(store => {
      if (!WITH_DELIVERY) {
        return !(store.type === "OUT_OF_COVERAGE");
      }
      return store;
    });
    const hasErrors = !!products?.length || !!validationErrors?.length;
    if (hasErrors && !shouldMock) {
      setDisabled(true);
      setErrors(errors);
    }

    // const talkShopData = {
    //   to: `whatsapp:+${talkShopIdentifier.trim()}`,
    //   from: `whatsapp:+${PHONE_NUMBER}`,
    //   body: `${getCartMessage(
    //     cartProducts,
    //     showModifiers,
    //     shoppingCart,
    //     selectedCountry
    //   )}`
    // };

    // if (talkShop) {
    //   mutate(talkShopData, {
    //     onSuccess: () => {
    //       router.push(RETURN_TO_APP_URL);
    //     }
    //   });
    //   return;
    // }

    // router.push("/checkout?redirect=false");
  };

  return (
    <Styles className={`CartPayButton ${className}`} talkShop={!!talkShop}>
      <Button
        onClick={payButtonHandler}
        disabled={empty || isLoading || !WITH_PURCHASE}
        className="Cart__summary__button"
      >
        Ir a pagar
      </Button>
    </Styles>
  );
};

CartPayButton.defaultProps = {
  className: ""
};

export default CartPayButton;
